import { Link } from "react-router-dom";

export function ThankYouMessage({ customMessage }) {
    return (
        <div className="alert alert-warning mt-3 mb-0">
            {customMessage || (
                <p>
                    Thanks for sharing. <br />
                    Love ABC? Get it <Link to={"/register"}>here</Link>!
                </p>
            )}
        </div>
    );
} 