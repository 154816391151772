import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

export function Icon({ leadCreated, type }) {
    const width = 18;
    const { t } = useTranslation();

    // Define dynamic image source and alt text
    let src = '';
    let alt = 'note-type';
    let title = '';

    switch (type) {
        case 'REMARK':
            src = '/note-sticky-regular.svg';
            title = t("LEADNOTETYPE." + type);
            break;
        case 'MEETING':
            src = '/screen-users-regular.svg';
            break;
        case 'CALL':
            src = '/square-phone-regular.svg';
            break;
        case 'PRESENTATION':
            src = '/presentation-screen-regular.svg';
            break;
        case 'DEMO':
            src = '/laptop-binary-regular.svg';
            break;
        case 'WARNING':
            src = '/triangle-exclamation-regular.svg';
            break;
        case 'REQUEST':
            src = '/arrow-up-left-from-circle-sharp-regular.svg';
            break;
        case 'USER_INTERESTS':
            src = `/user-sharp-${leadCreated ? 'orange' : 'black'}.png`;
            break;
        default:
            break;
    }

    return (
        <img
            src={src}
            alt={alt}
            width={width}
            title={title}
        />
    );
}

// Prop types validation
Icon.propTypes = {
    leadCreated: PropTypes.bool.isRequired,
    type: PropTypes.string.isRequired,
};

