import { fetchWrapper } from "_helpers";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Loading } from "_components";

export function Wallets({ height = 48 }) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const authUser = useSelector(x => x.auth.user);

    const downloadBusinessCardApple = () => {
        setIsLoading(true);
        setError(null);

        fetchWrapper.plainFetch(process.env.REACT_APP_API_URL + "/vendor/wallet/apple/business-card")
            .then(response => {
                if (!response.ok) {
                    throw new Error('Download failed');
                }
                return response.arrayBuffer();
            })
            .then(data => {
                saveByteArray(authUser.firstName + ".pkpass", new Uint8Array(data));
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching byte array:', error);
                setError('Failed to download pass');
                setIsLoading(false);
            });
    };

    const saveByteArray = (fileName, byte) => {
        const blob = new Blob([byte], { type: "application/vnd.apple.pkpass" });
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = fileName;
        link.click();

        // Clean up by revoking the object URL
        setTimeout(() => window.URL.revokeObjectURL(url), 100);
    };

    const downloadBusinessCardGoogle = () => {
        setIsLoading(true)
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/wallet/google/business-card").then(response => {
            setIsLoading(false)
            window.location.href = response.data.value;
        });
    }

    return (
        <div className="d-flex flex-column">
            <div className="mb-3">
                <button
                    onClick={downloadBusinessCardApple}
                    disabled={isLoading}
                    aria-label="Add to Apple Wallet"
                    className="bg-transparent border-0 cursor-pointer p-0"
                >
                    <img
                        src="/US-UK_Add_to_Apple_Wallet_RGB_101421.svg"
                        alt="Add to Apple Wallet"
                        width="auto"
                        height={height}
                    />
                </button>
            </div>
            {/*
            <div>
                <button
                    onClick={downloadBusinessCardGoogle}
                    disabled={isLoading}
                    aria-label="Add to Google Wallet"
                    className="bg-transparent border-0 cursor-pointer p-0"
                >
                    <img
                        src="/enUS_add_to_google_wallet_add-wallet-badge.svg"
                        alt="Add to Google Wallet"
                        width="auto"
                        height={42}
                    />
                </button>
            </div>
            */}
            {isLoading && <Loading />}
            {error && <div className="text-danger mt-2">{error}</div>}
        </div>
    );
}