import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useMemo } from "react";
import { SubmitBar, TextInputField } from "_components";

export function RealEstateRoom({ handleConfirmed, id, room }) {
    // Form validation schema
    const validationSchema = useMemo(() => {
        return Yup.object({
            room: Yup.object({
                roomType: Yup.string().required('Room type is required field')
            })
        });
    }, []);

    // Form options with validation
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true,
        defaultValues: {
            room: {
                id: id,
                roomType: '',
                level: '',
                dimension1: '',
                dimension2: ''
            }
        }
    };

    // Initialize form
    const {
        register,
        handleSubmit,
        formState,
        setValue,
        getValues,
        reset
    } = useForm(formOptions);

    const { errors, isSubmitting } = formState;

    // Load room data when component mounts or room changes
    useEffect(() => {
        if (room) {
            // Reset form with room data to ensure clean state
            reset({
                room: {
                    id: room.id,
                    roomType: room.roomType || '',
                    level: room.level || '',
                    dimension1: room.dimension1 || '',
                    dimension2: room.dimension2 || ''
                }
            });
        } else {
            // Initialize with empty values but set the ID
            reset({
                room: {
                    id: id,
                    roomType: '',
                    level: '',
                    dimension1: '',
                    dimension2: ''
                }
            });
        }
    }, [room, id, reset]);

    // Handle form submission
    const onSubmitRoom = () => {
        // Ensure ID is set correctly
        setValue('room.id', id);

        // Get form values and pass to parent component
        const roomData = getValues('room');
        handleConfirmed(roomData);
    };

    return (
        <form onSubmit={handleSubmit(onSubmitRoom)}>
            {/* Hidden field for room ID */}
            <input type="hidden" {...register('room.id')} />

            {/* Room Fields */}
            <TextInputField
                register={register}
                label="Room type"
                name="room.roomType"
                maxLength="100"
                error={errors.room?.roomType}

            />

            <TextInputField
                register={register}
                label="Level"
                name="room.level"
                maxLength="100"

            />

            <TextInputField
                register={register}
                label="Dimension 1"
                name="room.dimension1"
                maxLength="50"

            />

            <TextInputField
                register={register}
                label="Dimension 2"
                name="room.dimension2"
                maxLength="50"

            />

            {/* Submit Bar */}
            <SubmitBar
                isSubmitting={isSubmitting}
                labelConfirm="Confirm"
                showCancel={false}
            />
        </form>
    );
}