import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Tags from "@yaireo/tagify/dist/react.tagify";
import "@yaireo/tagify/dist/tagify.css";
import { fetchWrapper } from "_helpers";

export function AdvancedSearch({
    register,
    watch,
    setValue,
    clearFilter,
    renderSelectionControls,
    offers
}) {
    const { t } = useTranslation();
    const tagifyRef = useRef();
    const [whitelist, setWhitelist] = useState([]);
    const [offerStates, setOfferStates] = useState([]);
    const [tags, setTags] = useState([]);

    const offerId = watch('offerId');

    // Load offer states when offer ID changes
    const loadOfferStates = useCallback(() => {
        if (!offerId) return;

        fetchWrapper.get(`${process.env.REACT_APP_API_URL}/vendor/offer/${offerId}/states`)
            .then(response => {
                setOfferStates(response.data);
            });
    }, [offerId]);

    // Load tags based on search input
    const loadTags = useCallback((e) => {
        const value = e.detail.value;

        fetchWrapper.post(`${process.env.REACT_APP_API_URL}/vendor/tags/search`, { "searchPattern": value })
            .then(response => {
                const whitelist = response.data.map(item => ({
                    value: item.value,
                    style: `background-color: ${item.backgroundColor};`,
                    backgroundColor: item.backgroundColor
                }));

                setWhitelist(whitelist);
            });
    }, []);

    // Handle tag changes
    const handleChange = useCallback(e => {
        const newTags = e.detail.tagify.value.map(item => item.value);
        setTags(newTags);
        setValue('tags', newTags);
    }, [setValue]);

    // Effect: Load offer states when offer ID changes
    useEffect(() => {
        if (offerId) {
            loadOfferStates();
        } else {
            setValue("offerStateId", "");
            setOfferStates([]);
        }
    }, [loadOfferStates, offerId, setValue]);

    // Handle clear filter - add tag clearing logic here
    const handleClearFilter = useCallback(() => {
        clearFilter();

        if (tagifyRef.current) {
            tagifyRef.current.removeAllTags();
            setTags([]);
            setValue('tags', []);
        }
    }, [clearFilter, setValue]);

    return (
        <>
            <div className="row mb-2" style={{ transition: "0.5s all" }}>
                <div className="col">
                    <div className="form-group mb-2">
                        <label htmlFor="lastInteraction" className="mb-1">Last interaction within</label>
                        <select
                            id="lastInteraction"
                            className="form-select"
                            {...register('lastInteractionWithin')}
                        >
                            <option value="">All</option>
                            <option value="DAY">Day</option>
                            <option value="WEEK">Week</option>
                            <option value="MONTH">Month</option>
                            <option value="SIX_MONTHS">6 Months</option>
                        </select>
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="interactions" className="mb-1">Number of interactions</label>
                        <select
                            id="interactions"
                            className="form-select"
                            {...register('interactionsCount')}
                        >
                            <option value="">All</option>
                            <option value="ONE_PLUS">1+</option>
                            <option value="TWO_PLUS">2+</option>
                            <option value="FOUR_PLUS">4+</option>
                            <option value="SIX_PLUS">6+</option>
                            <option value="TEN_PLUS">10+</option>
                        </select>
                    </div>
                    <div className="form-group mb-2">
                        <label htmlFor="leadtags" className="mb-1" style={{ display: 'block' }}>Tags</label>
                        <Tags
                            id="leadtags"
                            tagifyRef={tagifyRef}
                            settings={{
                                blacklist: [],
                                backspace: "edit",
                                placeholder: "Enter tag",
                                editTags: false,
                                enforceWhitelist: true,
                                dropdown: {
                                    position: 'text',
                                    enabled: 1,
                                },
                                transformTag: (tagData) => {
                                    if (tagData.backgroundColor) {
                                        tagData.color = tagData.backgroundColor;
                                        tagData.style = "--tag-bg:" + tagData.backgroundColor;
                                    }
                                }
                            }}
                            onInput={loadTags}
                            onAdd={handleChange}
                            onRemove={handleChange}
                            whitelist={whitelist}
                        />
                    </div>
                    {offers.length > 0 && (
                        <div className="form-group mb-2">
                            <label htmlFor="offer" className="mb-1">Offer</label>
                            <select
                                id="offer"
                                {...register('offerId')}
                                className="form-select"
                            >
                                <option value="">All</option>
                                {offers.map(offer => (
                                    <option key={offer.id} value={offer.id}>
                                        {offer.title}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                    {offerStates.length > 0 && (
                        <div className="form-group mb-2">
                            <label htmlFor="offerState" className="mb-1">Offer state</label>
                            <select
                                id="offerState"
                                {...register('offerStateId')}
                                className="form-select"
                            >
                                <option value="">All</option>
                                {offerStates.map(offerState => (
                                    <option key={offerState.id} value={offerState.id}>
                                        {t(`LEADOFFERSTATE.${offerState.state}`)}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                </div>
            </div>
            <div className="row mb-2">
                <div className="col">
                    {renderSelectionControls()}
                    <div className="float-end">
                        <a onClick={handleClearFilter} className="cursor">Clear Filters</a>
                    </div>
                </div>
            </div>
        </>
    );
}