import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import moment from "moment";

import { fetchWrapper } from '_helpers';
import { ConfirmationDialog, Loading, NoResults, Paginate } from "_components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { Tab, Tabs } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import TextTruncate from "react-text-truncate";
import { OfferPreview, OfferPrintPrepare } from ".";

export function Offers() {
    const { register, watch, getValues } = useForm();
    const searchPattern = watch('searchPattern');
    const [loading, setLoading] = useState(false);
    const [key, setKey] = useState('all');
    const [showArchived, setShowArchived] = useState(false);
    const [offers, setOffers] = useState([]);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [deleteDialog, setDeleteDialog] = useState({
        show: false,
        id: null
    });
    const [activationDialog, setActivationDialog] = useState({
        show: false,
        id: null
    });
    const [deactivationDialog, setDeactivationDialog] = useState({
        show: false,
        id: null
    });
    const [archiveDialog, setArchiveDialog] = useState({
        show: false,
        id: null
    });
    const [unarchiveDialog, setUnarchiveDialog] = useState({
        show: false,
        id: null
    });
    const [previewDialog, setPreviewDialog] = useState({
        show: false,
        code: null,
        invitationCode: null
    });
    const [printDialog, setPrintDialog] = useState({
        show: false,
        id: null,
        code: null
    });

    useEffect(() => {
        setPage(0);
    }, [searchPattern, showArchived])

    useEffect(() => {
        loadOffers();
    }, [page, showArchived, searchPattern])

    useEffect(() => {
        if (key === "all") {
            setShowArchived(false);
        } else if (key === "archived") {
            setShowArchived(true);
        }
    }, [key])

    function loadOffers() {
        setLoading(true);
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/vendor/offers", { "searchPattern": getValues('searchPattern'), "pageNumber": page, "pageSize": 10, "archive": showArchived }).then(response => {
            setOffers(response.data.content);
            setTotalPages(response.data.totalPages);
            setLoading(false);
        });
    }

    const handlePageClick = (event) => {
        setPage(event.selected);
    };

    function activateOffer() {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/offer/" + activationDialog.id + "/activate").then(response => {
            setActivationDialog({ show: false })
            loadOffers();
        });
    }

    function deactivateOffer() {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/offer/" + deactivationDialog.id + "/deactivate").then(response => {
            setDeactivationDialog({ show: false })
            loadOffers();
        });
    }

    function archiveOffer() {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/offer/" + archiveDialog.id + "/archive").then(response => {
            setArchiveDialog({ show: false })
            loadOffers();
        });
    }

    function unarchiveOffer() {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/offer/" + unarchiveDialog.id + "/unarchive").then(response => {
            setUnarchiveDialog({ show: false })
            loadOffers();
        });
    }

    function deleteOffer() {
        fetchWrapper.delete(process.env.REACT_APP_API_URL + "/vendor/offer/" + deleteDialog.id).then(response => {
            setDeleteDialog({ show: false })
            loadOffers();
        });
    }

    function previewOffer(id, code) {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/offer/" + id + "/preview").then(response => {
            if (response.status === 200) {
                setPreviewDialog({ show: true, code: code, invitationCode: response.data.value })
            }
        });
    }

    return (
        <>
            <div className="row justify-content-center">
                <div className="col-lg-11 col-xl-9">
                    <div className="mt-5 mb-3">
                        <input type="text" className="form-control" name="searchPattern" {...register('searchPattern')} placeholder="Search offers" />
                    </div>
                    <div className="">
                        <Link to="/offer/new">Add new</Link>
                    </div>
                    <Tabs defaultActiveKey="all" className="mb-3 justify-content-center" onSelect={(k) => setKey(k)}>
                        <Tab eventKey="all" title="All" >
                            {loading === false ?
                                <>
                                    {offers.length === 0 ? <NoResults /> :
                                        <table className="table">
                                            <tbody>
                                                {offers.map(offer =>
                                                    <tr key={offer.id}>
                                                        <td>
                                                            {offer.title}
                                                            {offer.description && <p className="mb-0 text-secondary">
                                                                <TextTruncate
                                                                    line={1}
                                                                    truncateText="…"
                                                                    text={offer.description}
                                                                />
                                                            </p>}
                                                            <p>
                                                                {offer.status === 'ACTIVE' && <span className="badge text-bg-success me-1">Active</span>}
                                                                {offer.status === 'INACTIVE' && <span className="badge text-bg-secondary me-1">Inactive</span>}
                                                                Created on {moment(offer.creationDate).format('MMM Do YYYY')}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <div className="float-end">
                                                                <div className="dropdown">
                                                                    <button className="btn btn-link dropdown-toggle shadow" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                        <FontAwesomeIcon icon={faEllipsisV} />
                                                                    </button>
                                                                    <ul className="dropdown-menu dropdown-dark">
                                                                        <li><DropdownItem tag={Link} href={`/offer/edit/` + offer.id} className="dropdown-item">Edit</DropdownItem></li>
                                                                        <li><button className="dropdown-item" onClick={() => setDeleteDialog({ show: true, id: offer.id })}>Delete</button></li>
                                                                        {offer.status === 'INACTIVE' && <li><button className="dropdown-item" onClick={() => setActivationDialog({ show: true, id: offer.id })}>Activate</button></li>}
                                                                        {offer.status === 'ACTIVE' && <li><button className="dropdown-item" onClick={() => setDeactivationDialog({ show: true, id: offer.id })}>Deactivate</button></li>}
                                                                        <li><button className="dropdown-item" onClick={() => setArchiveDialog({ show: true, id: offer.id })}>Archive</button></li>
                                                                        <li><button className="dropdown-item" onClick={() => previewOffer(offer.id, offer.code)}>Preview</button></li>
                                                                        <li><button className="dropdown-item" onClick={() => setPrintDialog({ show: true, id: offer.id, code: offer.code })}>Print</button></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    }
                                </>
                                : <Loading />}
                        </Tab>
                        <Tab eventKey="archived" title="Archived" >
                            {loading === false ?
                                <table className="table">
                                    <tbody>
                                        {offers.map(offer =>
                                            <tr key={offer.id}>
                                                <td>
                                                    {offer.title}
                                                    {offer.description && <p className="mb-0 text-secondary">
                                                        <TextTruncate
                                                            line={1}
                                                            truncateText="…"
                                                            text={offer.description}
                                                        />
                                                    </p>}
                                                    <p>Created on {moment(offer.creationDate).format('MMM Do YYYY')}</p>
                                                </td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn btn-link dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <FontAwesomeIcon icon={faEllipsisV} />
                                                        </button>
                                                        <ul className="dropdown-menu">
                                                            <li><button className="dropdown-item" onClick={() => setUnarchiveDialog({ show: true, id: offer.id })}>Unarchive</button></li>
                                                        </ul>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                                : <Loading />}
                        </Tab>
                    </Tabs>
                    <Paginate totalPages={totalPages} handlePageClick={handlePageClick} />
                </div>
            </div>
            <ConfirmationDialog
                show={activationDialog.show}
                message="You are about to activate this offer. Are you sure?"
                handleConfirm={activateOffer}
                handleCancel={() => setActivationDialog({ show: false, id: null })}
            />
            <ConfirmationDialog
                show={deactivationDialog.show}
                message="You are about to deactivate this offer. Are you sure?"
                handleConfirm={deactivateOffer}
                handleCancel={() => setDeactivationDialog({ show: false, id: null })}
            />
            <ConfirmationDialog
                show={archiveDialog.show}
                message="You are about to archive this offer. Are you sure?"
                handleConfirm={archiveOffer}
                handleCancel={() => setArchiveDialog({ show: false, id: null })}
            />
            <ConfirmationDialog
                show={unarchiveDialog.show}
                message="You are about to unarchive this offer. Are you sure?"
                handleConfirm={unarchiveOffer}
                handleCancel={() => setUnarchiveDialog({ show: false, id: null })}
            />
            <ConfirmationDialog
                show={deleteDialog.show}
                message="You are about to delete this offer. Are you sure?"
                handleConfirm={deleteOffer}
                handleCancel={() => setDeleteDialog({ show: false, id: null })}
            />
            {previewDialog.show &&
                <OfferPreview
                    show={previewDialog.show}
                    code={previewDialog.code}
                    invitationCode={previewDialog.invitationCode}
                    cancelCallback={() => setPreviewDialog({ show: false })}
                />
            }
            {printDialog.show &&
                <OfferPrintPrepare
                    show={printDialog.show}
                    offerId={printDialog.id}
                    offerCode={printDialog.code}
                    cancelCallback={() => setPrintDialog({ show: false })}
                />
            }
        </>
    );

}
