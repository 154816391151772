import { useContext, useEffect, useState } from "react";
import { LeadTags } from "./tags";
import { LeadNotes } from "./notes";
import { LeadOffers } from "./offers";
import { LeadAudiences } from "./audiences";
import { InteractionsSummary, LogEvents } from "./logevents";
import { LeadMessages } from "./messages";
import { Delete } from "./Delete";
import AppContext from "_context/AppContext";
import { LeadHeader } from "./LeadHeader"; // Import the new component

export function Details({ lead, handleBackClick, onLeadUpdated }) {
    const { reloadMessages } = useContext(AppContext);

    return (
        <>
            {lead &&
                <>
                    <LeadHeader lead={lead} handleBackClick={handleBackClick} />
                    <div className="row mb-2" style={{ backgroundColor: "#e2e2e2", color: "white", height: "2.4rem" }}>
                        <div className="col-3 text-center">
                            <a className="btn" href={"tel:" + lead.phoneNumber}>
                                <img src="phone-sharp-regular.svg" alt="phone" width={24} />
                            </a>
                        </div>
                        <div className="col-3 text-center">
                            {lead.email &&
                                <a className="btn" href={"mailto:" + lead.email}>
                                    <img src="envelope-sharp-regular.svg" alt="email" width={24} />
                                </a>
                            }
                        </div>
                        <div className="col-3 text-center">
                            <a className="btn" href="#messages-section">
                                <img src="comments-sharp-regular.svg" alt="messages" width={24} />
                            </a>
                        </div>
                        <div className="col-3 text-center">
                            <Delete leadId={lead.id} successCallBack={handleBackClick} />
                        </div>
                    </div>
                    {lead.email && <p className="mb-0">{lead.email}</p>}
                    {lead.phoneNumber && <p className="mb-0">{lead.phoneNumber}</p>}
                    <InteractionsSummary leadId={lead.id} />
                    <div className="row mb-2">
                        <div className="col">
                            <LeadTags leadId={lead.id} />
                        </div>
                    </div>
                    <LeadAudiences leadId={lead.id} onLeadUpdated={onLeadUpdated} />
                    <LeadOffers leadId={lead.id} />
                    <LeadNotes leadId={lead.id} />
                    <LeadMessages leadId={lead.id} reload={reloadMessages} />
                    <LogEvents leadId={lead.id} />
                </>
            }
        </>
    );
}