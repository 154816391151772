import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { ManageSubscriptions } from "./ManageSubscriptions";
import { RequestManage } from "./RequestManage";

export function Manage() {
    const [lead, setLead] = useState(null);
    const [manageSubscriptions, setManageSubscriptions] = useState([]);
    const [confirmationCode, setConfirmationCode] = useState(null);

    const requestManageSuccess = (confirmationCode, status, data) => {
        setLead(data.lead);
        setManageSubscriptions(data.manageSubscriptions);
        setConfirmationCode(confirmationCode);
    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-sm-5">
                    <h3 className="mt-4 mb-4 text-center">Manage account</h3>
                    {lead === null ?
                        <RequestManage callback={requestManageSuccess}/>
                    :
                        <>
                            <p className="mb-0">Your name</p>
                            <h4 className="mt-0">{lead.fullName}</h4>
                            <p className="mb-0">Your phone number</p>
                                <h5 className="mt-0">{lead.phoneNumber}</h5>
                            {/*    
                            {lead.email && 
                            <>
                                <p className="mb-0">Your email</p>
                                <h5 className="mt-0 mb-0">{lead.email}{lead.emailVerified === true && <span className="ms-1"><FontAwesomeIcon icon={faCheckCircle}/></span>}</h5>
                                {lead.emailVerified === false &&
                                    <p className="mt-0 text-secondary fs-6">Your email is not verified. Please request a verification.</p>
                                }
                            </>}
                            */}
                            <ManageSubscriptions
                                leadManageSubscriptions={manageSubscriptions}
                                confirmationCode={confirmationCode}
                            />
                        </>
                    }
                </div>
            </div>
        </div>
    )

}