import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import { ConfirmationDialog, SubmitBar, TextInputField } from "_components";
import { CountriesSelectField } from "_components/CountriesSelectField";
import { fetchWrapper } from "_helpers";
import { useEffect, useState, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import FileResizer from "react-image-file-resizer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CallToAction, { callToActionValidationSchema } from '../CallToAction';

export function Event({ offer, selectedTemplateId }) {
    const navigate = useNavigate();
    
    // State management
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [imageSrc, setImageSrc] = useState(null);
    const [mainImage, setMainImage] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [removeImages, setRemoveImages] = useState([]);
    const [deletePictureDialogState, setDeletePictureDialogState] = useState({
        show: false,
        id: null
    });

    // Form validation schema
    const validationSchema = useMemo(() => {
        return Yup.object({
            title: Yup.string().required('Title is required field'),
            data: Yup.object({
                locationPlace: Yup.string().required('Location place is required field')
            }),
            // Spread the Call To Action validation schema fields here
            ...callToActionValidationSchema.fields
        });
    }, []);

    // Form setup
    const {
        control,
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setValue,
        getValues,
        setError
    } = useForm({
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    });

    // Load offer data when component mounts or offer changes
    useEffect(() => {
        if (!offer) return;

        // Set basic fields
        setValue('title', offer.title);
        setValue('description', offer.description);
        setValue('callToActionText', offer.callToActionText);
        setValue('callToActionURL', offer.callToActionURL);
        
        // Parse and set content fields
        try {
            const content = JSON.parse(offer.content);
            setValue('data.locationPlace', content.locationPlace);
            setValue('data.address1', content.address1);
            setValue('data.address2', content.address2);
            setValue('data.state', content.state);
            setValue('data.city', content.city);
            setValue('data.postalCode', content.postalCode);
            setValue('data.description', content.description);
            
            // Set country, dates
            if (content.country) {
                setSelectedCountry(content.country);
            }
            
            if (content.fromDate) {
                const fromDate = new Date(content.fromDate);
                setStartDate(fromDate);
                setValue('data.fromDate', fromDate);
            }
            
            if (content.toDate) {
                const toDate = new Date(content.toDate);
                setEndDate(toDate);
                setValue('data.toDate', toDate);
            }
            
            // Load images
            loadPictures();
        } catch (error) {
            console.error("Error parsing offer content:", error);
        }
    }, [offer, setValue]);

    // File handling functions
    const onSelectFile = (e) => {
        if (!e.target.files || e.target.files.length === 0) return;
        
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            setImageSrc(reader.result);
            if (mainImage) {
                removePictureFromEvent();
            }
        });
        reader.readAsDataURL(e.target.files[0]);
    };

    const loadPictures = async () => {
        try {
            const response = await fetchWrapper.get(
                `${process.env.REACT_APP_API_URL}/vendor/offer/${offer.id}/pictures`
            );
            const pictures = response.data;
            if (pictures && pictures.length > 0) {
                setMainImage(pictures[0]);
            }
        } catch (error) {
            console.error("Error loading pictures:", error);
        }
    };

    const resizeFile = (file) => {
        return new Promise((resolve) => {
            FileResizer.imageFileResizer(
                file,
                2048,
                1080,
                "png",
                100,
                0,
                (uri) => resolve(uri),
                "blob"
            );
        });
    };

    const dataURLtoBlob = (dataurl) => {
        const arr = dataurl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        const n = bstr.length;
        const u8arr = new Uint8Array(n);
        
        for (let i = 0; i < n; i++) {
            u8arr[i] = bstr.charCodeAt(i);
        }
        
        return new Blob([u8arr], { type: mime });
    };

    // Date handling functions
    const handleFromDateChange = (date) => {
        setStartDate(date);
        setValue('data.fromDate', date);
        
        // Set end date to 1 hour after start date if not already set
        if (!endDate || startDate >= endDate) {
            const newEndDate = new Date(date);
            newEndDate.setHours(newEndDate.getHours() + 1);
            setEndDate(newEndDate);
            setValue('data.toDate', newEndDate);
        }
    };

    const handleToDateChange = (date) => {
        setEndDate(date);
        setValue('data.toDate', date);
    };

    // Dialog functions
    const showDeletePictureDialog = (id = null) => {
        setDeletePictureDialogState({ show: true, id });
    };

    const hideDeletePictureDialog = () => {
        setDeletePictureDialogState({ show: false, id: null });
    };

    const removePictureFromEvent = () => {
        if (mainImage) {
            setRemoveImages([mainImage.info]);
        }
        setMainImage(null);
        setImageSrc(null);
        hideDeletePictureDialog();
    };

    // Form submission
    const onSubmit = async () => {
        // Validate dates
        if (startDate && startDate < new Date()) {
            setError("data.fromDate", { 
                type: 'custom', 
                message: "Start date must be after current date/time." 
            });
            return;
        }

        if (startDate && endDate && startDate >= endDate) {
            setError("data.fromDate", { 
                type: 'custom', 
                message: "Start date must be before end date." 
            });
            return;
        }

        try {
            const formData = new FormData();
            
            // Add image if selected
            if (imageSrc) {
                const image = await resizeFile(dataURLtoBlob(imageSrc));
                formData.append("files", image);
            }
            
            // Create event data object
            const eventData = {
                title: getValues('title'),
                description: getValues('description'),
                callToActionText: getValues('callToActionText'),
                callToActionURL: getValues('callToActionURL'),
                data: getValues('data')
            };

            if (offer) {
                // Update existing offer
                formData.append("removeImages", removeImages);
                formData.append("offer", JSON.stringify(eventData));
                
                const response = await fetchWrapper.postFormData(
                    `${process.env.REACT_APP_API_URL}/vendor/offer/${offer.id}`, 
                    formData
                );
                
                if (response.status === 200) {
                    navigate("/offers");
                }
            } else {
                // Create new offer
                eventData.templateId = selectedTemplateId;
                formData.append("offer", JSON.stringify(eventData));
                
                const response = await fetchWrapper.postFormData(
                    `${process.env.REACT_APP_API_URL}/vendor/offer`, 
                    formData
                );
                
                if (response.status === 200) {
                    navigate("/offers");
                }
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    };

    return (
        <div className="row justify-content-center">
            <div className="col-lg-5">
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* Basic Info Section */}
                    <TextInputField 
                        register={register} 
                        label="Title" 
                        name="title" 
                        error={errors.title} 
                        maxLength="100" 
                    />
                    
                    <TextInputField 
                        register={register} 
                        label="Subtitle" 
                        name="description" 
                        error={errors.description} 
                        textarea={true} 
                    />
                    
                    {/* Image Section */}
                    {(!imageSrc && !mainImage) && (
                        <div className="form-group mb-2">
                            <label className="mb-0">Image</label>
                            <input 
                                className="form-control" 
                                type="file" 
                                onChange={onSelectFile} 
                                accept="image/*" 
                            />
                        </div>
                    )}
                    
                    {imageSrc && (
                        <div style={{ flex: "1", position: "relative", margin: "0.5rem" }}>
                            <img 
                                src={imageSrc} 
                                alt="Event preview" 
                                className="card-img-top" 
                                style={{ width: "100%", objectFit: "cover" }} 
                            />
                            <span className="position-absolute top-0 start-100 translate-middle rounded-pill bg-light">
                                <button 
                                    type="button" 
                                    className="btn btn-link btn-sm text-danger" 
                                    onClick={() => showDeletePictureDialog()} 
                                >
                                    <FontAwesomeIcon icon={faX} />
                                </button>
                            </span>
                        </div>
                    )}
                    
                    {mainImage && (
                        <div style={{ flex: "1", position: "relative", margin: "0.5rem" }}>
                            <img 
                                src={`/uploads/offer/${mainImage.path}`} 
                                alt={mainImage.path} 
                                className="card-img-top" 
                                style={{ width: "100%", objectFit: "cover" }} 
                            />
                            <span className="position-absolute top-0 start-100 translate-middle rounded-pill bg-light">
                                <button 
                                    type="button" 
                                    className="btn btn-link btn-sm text-danger" 
                                    onClick={() => showDeletePictureDialog(mainImage.info)}
                                >
                                    <FontAwesomeIcon icon={faX} />
                                </button>
                            </span>
                        </div>
                    )}
                    
                    {/* Date Range Section */}
                    <div className="row">
                        <div className="col">
                            <Controller
                                name="data.fromDate"
                                control={control}
                                rules={{ required: true }}
                                render={({ fieldState: { error } }) => (
                                    <div className="form-group mb-2">
                                        <label className="mb-0">From date</label>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={handleFromDateChange}
                                            className="form-control"
                                            minDate={new Date()}
                                            showTimeSelect
                                            timeFormat="p"
                                            timeIntervals={15}
                                            timeCaption="time"
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                        />
                                        {error?.message && (
                                            <div className="text-danger mt-1">{error.message}</div>
                                        )}
                                    </div>
                                )}
                            />
                        </div>
                        <div className="col">
                            <Controller
                                name="data.toDate"
                                control={control}
                                rules={{ required: true }}
                                render={({ fieldState: { error } }) => (
                                    <div className="form-group mb-2">
                                        <label className="mb-0">To date</label>
                                        <DatePicker
                                            selected={endDate}
                                            onChange={handleToDateChange}
                                            className="form-control"
                                            minDate={startDate || new Date()}
                                            showTimeSelect
                                            timeFormat="p"
                                            timeIntervals={15}
                                            timeCaption="time"
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                        />
                                        {error?.message && (
                                            <div className="text-danger mt-1">{error.message}</div>
                                        )}
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                    
                    {/* Location Section */}
                    <TextInputField 
                        register={register} 
                        label="Location Place" 
                        name="data.locationPlace" 
                        error={errors.data?.locationPlace} 
                        maxLength="100" 
                    />
                    
                    <TextInputField 
                        register={register} 
                        label="Address 1" 
                        name="data.address1" 
                        maxLength={100} 
                    />
                    
                    <TextInputField 
                        register={register} 
                        label="Address 2" 
                        name="data.address2" 
                        maxLength={100} 
                    />
                    
                    <CountriesSelectField 
                        register={register} 
                        setValue={setValue} 
                        name="data.country" 
                        valueName={true} 
                        selected={selectedCountry} 
                    />
                    
                    <div className="row">
                        <div className="col">
                            <TextInputField 
                                register={register} 
                                label="State" 
                                name="data.state" 
                                maxLength={100} 
                            />
                        </div>
                        <div className="col">
                            <TextInputField 
                                register={register} 
                                label="City" 
                                name="data.city" 
                                maxLength={100} 
                            />
                        </div>
                        <div className="col">
                            <TextInputField 
                                register={register} 
                                label="Zip" 
                                name="data.postalCode" 
                                maxLength={100} 
                            />
                        </div>
                    </div>
                    
                    <TextInputField 
                        register={register} 
                        label="Description" 
                        name="data.description" 
                        error={errors.data?.description} 
                        textarea={true} 
                    />
                    
                    {/* Call To Action Component */}
                    <CallToAction register={register} errors={errors} />
                    
                    <SubmitBar 
                        isSubmitting={isSubmitting} 
                        labelConfirm="Save" 
                        cancelLink="/offers" 
                    />
                </form>
                
                <ConfirmationDialog
                    show={deletePictureDialogState.show}
                    message="To remove the image from the offer, after Confirming deletion, click the Save button at the end of your work."
                    handleConfirm={removePictureFromEvent}
                    handleCancel={hideDeletePictureDialog}
                />
            </div>
        </div>
    );
}