import { useEffect, useState } from "react";
import { LeadInvitation } from "./leads";
import { Modal } from "react-bootstrap";
import { fetchWrapper } from "_helpers";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { Wallets } from "./Wallets";

export function Actions() {
    const authUser = useSelector(x => x.auth.user);
    const [showInvitationDialog, setShowInvitationDialog] = useState(false);
    const [invitationDialog, setInvitationDialog] = useState({
        show: false,
        contactDetails: null
    });
    const [qrCodeDialog, setQrCodeDialog] = useState({
        show: false,
        invitationCode: null
    });

    // Load invitation code from localStorage on component mount
    useEffect(() => {
        const savedInvitationCode = localStorage.getItem('invitationCode');
        if (savedInvitationCode) {
            setQrCodeDialog(prevState => ({
                ...prevState,
                invitationCode: savedInvitationCode
            }));
        }
    }, []);

    useEffect(() => {
        if (qrCodeDialog.show) {
            // Only fetch a new code if we don't already have one
            if (!qrCodeDialog.invitationCode) {
                getPersonalInvitationCode();
            }
        }
    }, [qrCodeDialog.show]);

    // Save invitation code to localStorage whenever it changes
    useEffect(() => {
        if (qrCodeDialog.invitationCode) {
            localStorage.setItem('invitationCode', qrCodeDialog.invitationCode);
        }
    }, [qrCodeDialog.invitationCode]);

    function getPersonalInvitationCode() {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/profile/lead-invitation").then(response => {
            setQrCodeDialog({...qrCodeDialog, invitationCode: response.data.value});
        });
    }



    return (
        <>
            <div className="row" id="dashboard-actions">
                <div className="col" >
                    <button onClick={() => {setShowInvitationDialog(true);setInvitationDialog({show:false}) }} className="btn btn-success rounded-pill" >Invite Lead</button>
                    <div className="float-end mt-1">
                        <a onClick={() => {setQrCodeDialog({show: true})}} className="cursor"><img src="/id-card-light.svg" alt="personal" width={32} height={32}/></a>
                    </div>
                    {(invitationDialog.show && invitationDialog.contactDetails) && 
                        <div className="alert alert-success alert-dismissible fade show mt-3 mb-0">
                            <p className="text-success mb-0">An invitation to {invitationDialog.contactDetails} sent.</p>
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>}
                </div>
            </div>
            <LeadInvitation show={showInvitationDialog} handleCallback={(contactDetails) => {setShowInvitationDialog(false);setInvitationDialog({show: true, contactDetails: contactDetails});}}/>
            <Modal show={qrCodeDialog.show} onHide={() => setQrCodeDialog({show: false})}>
                <Modal.Header closeButton>
                    <Modal.Title>Scan the QR code and connect with me.</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {qrCodeDialog.invitationCode &&
                        <>
                            <div className="text-center">
                                <QRCode
                                    id="lead-qr"
                                    size={128}
                                    style={{ height: "auto", maxWidth: "40%", width: "40%" }}
                                    value={process.env.REACT_APP_BASE_URL + "/" + authUser.channelName + "/" + qrCodeDialog.invitationCode}
                                    viewBox={`0 0 256 256`}
                                />
                            </div>
                            <div className="text-center mt-3">
                                <Wallets /> 
                            </div>

                        </>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}