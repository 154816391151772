import { useCallback, useEffect, useState } from "react";

import { ConfirmationCode, Loading, Socials, VCardPersonal } from "_components";
import { fetchWrapper } from "_helpers";
import { Footer } from "./Footer";
import { Modal } from "react-bootstrap";
import { SubscribeForm } from "./SubscribeForm";
import { BusinessCardUserBody } from "page";
import { Link, useParams } from "react-router-dom";
import { ThankYouMessage } from "./ThankYouMessage";

export function LeadConfirmation() {
    const { channelName, code } = useParams();
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState();
    const [company, setCompany] = useState();
    const [lead, setLead] = useState();
    const [phoneNumbersDialog, setPhoneNumbersDialog] = useState(false);
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    const [addresses, setAddresses] = useState([]);
    const [subscribed, setSubscribed] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const [firstName, setFirstName] = useState(lead?.firstName || "");
    const [lastName, setLastName] = useState(lead?.lastName || "");
    const [email, setEmail] = useState(lead?.email || "");

    useEffect(() => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/page/personal/" + channelName + "/" + code).then(response => {
            setProfile(response.data);
            let profileAddresses = [...response.data.addresses]
            if (response.data.company.addresses.length > 0) {
                profileAddresses.push(...response.data.company.addresses);
            }
            setAddresses(profileAddresses);
            let profilePhoneNumbers = [...response.data.phoneNumbers];
            if (response.data.company.phoneNumbers.length > 0) {
                profilePhoneNumbers.push(...response.data.company.phoneNumbers)
            }
            setPhoneNumbers(profilePhoneNumbers);
            setCompany(response.data.company);
            setLoading(false);
        });

        fetchWrapper.get(process.env.REACT_APP_API_URL + "/lead/invitation/" + channelName + "/" + code).then(response => {
            const lead = response.data;
            setLead(lead);
        });

    }, [])

    const handleSubmit = useCallback(async (formData, { setError }) => {
        try {
            const url = `${process.env.REACT_APP_API_URL}/lead/invitation/request/${channelName}/${code}`;
            const response = await fetchWrapper.post(url, formData);
            setFirstName(formData['firstName'])
            setLastName(formData['lastName'])
            setEmail(formData['email'])

            switch (response.status) {
                case 200:
                    setShowConfirmation(true);
                    break;
                case 536:
                    setError("phoneNumber", {
                        type: 'custom',
                        message: "Phone number is not valid! Please try again"
                    }, { shouldFocus: true });
                    break;
                case 553:
                default:
                    setError("phoneNumber", {
                        type: 'custom',
                        message: "Something went wrong. Please try again."
                    }, { shouldFocus: true });
                    break;
            }
        } catch (error) {
            setError("phoneNumber", {
                type: 'custom',
                message: "Something went wrong. Please try again."
            }, { shouldFocus: true });
        }
    }, [channelName, code]);

    const handleCloseConfirmation = () => {
        setShowConfirmation(false)
    }

    const handleConfirmationComplete = () => {
        setShowConfirmation(false)
        setSubscribed(true)
    }

    return (
        <>
            {loading && <Loading />}
            {profile &&
                <>
                    <BusinessCardUserBody profile={profile} />
                    <div className="row justify-content-center mb-1 g-0" style={{ position: "relative" }}>
                        <div className="col-10 col-md-5 col-lg-4">
                            {profile.about && <p className="mb-0 mt-1" style={{ whiteSpace: "pre-line" }}>{profile.about}</p>}
                        </div>
                    </div>
                    <div className="container">
                        <div className="row justify-content-center mb-4 g-0" style={{ position: "relative" }}>
                            <div className="col">
                                <Socials data={profile} />
                                <div className="row mt-3">
                                    <div className="col-6 text-center">
                                        <VCardPersonal profile={profile} company={company} phoneNumbers={phoneNumbers} addresses={addresses} />
                                    </div>
                                    <div className="col-6 text-center">
                                        <button className="btn btn-dark btn-10 float-start" onClick={() => setPhoneNumbersDialog(true)}>
                                            {"Call " + profile.firstName}
                                        </button>
                                    </div>
                                </div>
                                <div className="row justify-content-center mt-2 mb-1 g-0" style={{ position: "relative" }}>
                                    <div className="col-9 col-md-5 col-lg-3">
                                        {subscribed === false ?
                                            <SubscribeForm
                                                onSubmit={handleSubmit}
                                                showInterestsField={false}
                                                firstName={lead?.firstName || ""}
                                                lastName={lead?.lastName || ""}
                                                phoneNumber={lead?.phoneNumber || ""}
                                                email={lead?.email || ""}
                                                readOnlyPhone={true}
                                            />
                                            :
                                            <ThankYouMessage />
                                        }

                                        <Modal show={showConfirmation} onHide={handleCloseConfirmation}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Confirm your identity</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <ConfirmationCode
                                                    postBody={{ "firstName": firstName, "lastName": lastName, "email": email }}
                                                    url={`/lead/invitation/confirm`}
                                                    callback={handleConfirmationComplete}
                                                />
                                            </Modal.Body>
                                        </Modal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                    <Modal show={phoneNumbersDialog} onHide={() => setPhoneNumbersDialog(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Phone numbers</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {phoneNumbers.length > 0 &&
                                phoneNumbers.map(phoneNumber =>
                                    <div key={phoneNumber.value}>
                                        <strong>{phoneNumber.title}:</strong> <a className='mb-0' href={"tel:" + phoneNumber.value}>{phoneNumber.value}</a>
                                    </div>
                                )
                            }
                        </Modal.Body>
                    </Modal>
                </>
            }
        </>
    )
}