import { constants } from "_helpers";

export function CurrencyInputField({register, error, label, name}) {

    return (
        <div className="form-group mb-2">
            <label htmlFor="currency" className="mb-1">{label}</label>
            <select id="currency" {...register(name)} className={`form-select ${error ? 'is-invalid' : ''}`}>
                <option value="">Choose...</option>
                {constants.currencies?.map(currency => 
                    <option key={currency} value={currency}>{currency}</option>
                )}
            </select>
            <div className="invalid-feedback">{error?.message}</div>
        </div>
    )

}