import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

/**
 * A component for managing property images with a maximum limit
 */
const LimitedImageUpload = ({
    existingImages = [],
    newImages = [],
    onSelectFile,
    onDeleteImage,
    maxImages = 16,
    imagesPerRow = 4,
    minImageWidth = 150,
    imageHeight = 150,
    gap = 12,
    className = ''
}) => {
    const totalImages = existingImages.length + newImages.length;
    const isLimitReached = totalImages >= maxImages;
    const remainingSlots = maxImages - totalImages;

    // Modified file input handler that checks for the limit
    const handleFileSelect = (e) => {
        if (isLimitReached) {
            // Prevent upload and clear the input
            e.target.value = null;
            return;
        }

        onSelectFile(e);
    };

    // Determine the grid template columns based on imagesPerRow
    const getGridTemplateColumns = () => {
        if (imagesPerRow === 'auto-fit') {
            return `repeat(auto-fit, minmax(${minImageWidth}px, 1fr))`;
        } else {
            // Fixed number of columns
            return `repeat(${imagesPerRow}, minmax(0, 1fr))`;
        }
    };

    return (
        <div className={`limited-image-upload ${className}`}>
            {/* Upload Section with Limit Indicator */}
            <div className="row justify-content-center mb-3">
                <div className="col">
                    <div className="upload-container">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <label className="form-label mb-0">Property Images</label>
                            <span className={`badge ${isLimitReached ? 'bg-danger' : 'bg-secondary'}`}>
                                {totalImages}/{maxImages} images
                            </span>
                        </div>

                        <input
                            className="form-control"
                            type="file"
                            onChange={handleFileSelect}
                            accept="image/*"
                            disabled={isLimitReached}
                            aria-label="Upload property image"
                        />

                        {isLimitReached && (
                            <div className="alert alert-warning mt-2 py-2" role="alert">
                                <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />
                                Maximum image limit reached ({maxImages} images).
                            </div>
                        )}

                        {!isLimitReached && totalImages > 0 && (
                            <small className="text-muted d-block mt-1">
                                You can add {remainingSlots} more {remainingSlots === 1 ? 'image' : 'images'}.
                            </small>
                        )}
                    </div>
                </div>
            </div>

            {/* Image Gallery */}
            {totalImages > 0 && (
                <div
                    className="image-gallery mb-4"
                    style={{
                        display: 'grid',
                        gridTemplateColumns: getGridTemplateColumns(),
                        gap: `${gap}px`,
                    }}
                >
                    {/* Existing images from server */}
                    {existingImages.map((picture, index) => (
                        <PropertyImage
                            key={`existing-${picture.id}`}
                            src={`/uploads/offer/${picture.path}`}
                            index={index}
                            isExisting={true}
                            id={picture.id}
                            info={picture.info}
                            onDeleteClick={onDeleteImage}
                            height={imageHeight}
                        />
                    ))}

                    {/* Newly added images */}
                    {newImages.map((picture, index) => (
                        <PropertyImage
                            key={`new-${index}`}
                            src={picture}
                            index={index}
                            isExisting={false}
                            onDeleteClick={onDeleteImage}
                            height={imageHeight}
                        />
                    ))}
                </div>
            )}

            {totalImages === 0 && (
                <div className="text-center text-muted py-4 mb-4 border rounded">
                    No images uploaded yet. You can upload up to {maxImages} images.
                </div>
            )}
        </div>
    );
};

/**
 * Individual property image component
 */
const PropertyImage = ({ src, index, isExisting, id, info, onDeleteClick, height = 150 }) => {
    return (
        <div
            className="position-relative image-container"
            style={{ height: `${height}px`, width: '100%' }}
        >
            <img
                src={isExisting ? src : src}
                alt={`Property image ${index + 1}`}
                className="h-100 w-100"
                style={{ objectFit: "cover", borderRadius: "4px" }}
            />
            <button
                type="button"
                className="btn btn-sm btn-light position-absolute top-0 end-0 m-1 rounded-circle p-1"
                onClick={() => onDeleteClick(isExisting, index, id, info)}
                aria-label="Delete image"
                style={{ width: "24px", height: "24px", display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <FontAwesomeIcon icon={faX} size="xs" />
            </button>
        </div>
    );
};

LimitedImageUpload.propTypes = {
    existingImages: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        path: PropTypes.string.isRequired,
        info: PropTypes.any
    })),
    newImages: PropTypes.arrayOf(PropTypes.string),
    onSelectFile: PropTypes.func.isRequired,
    onDeleteImage: PropTypes.func.isRequired,
    maxImages: PropTypes.number,
    imagesPerRow: PropTypes.oneOfType([PropTypes.number, PropTypes.oneOf(['auto-fit'])]),
    minImageWidth: PropTypes.number,
    imageHeight: PropTypes.number,
    gap: PropTypes.number,
    className: PropTypes.string
};

PropertyImage.propTypes = {
    src: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    isExisting: PropTypes.bool.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    info: PropTypes.any,
    onDeleteClick: PropTypes.func.isRequired,
    height: PropTypes.number
};

export default LimitedImageUpload;