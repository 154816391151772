import React from 'react';
import PropTypes from 'prop-types';
import { constants } from '_helpers';

/**
 * CallToActionButton - A reusable component for rendering call-to-action links
 * that automatically handles website URLs and phone numbers differently.
 */
const CallToActionButton = ({
    url,
    text,
    className = "mt-3 float-start cursor",
    style = { fontWeight: 600 },
    suffix = " >>",
    normalizeUrl = (url) => {
        // Default URL normalizer ensures URL has protocol
        if (url && !url.startsWith('http://') && !url.startsWith('https://')) {
            return `https://${url}`;
        }
        return url;
    }
}) => {
    // Don't render anything if no URL is provided
    if (!url) return null;

    // Determine if the URL is a website or phone number
    const isWebsite = constants.websiteRegExp.test(url);

    // Prepare the appropriate href
    const href = isWebsite
        ? normalizeUrl(url)
        : `tel:${url}`;

    // Set appropriate attributes based on URL type
    const linkProps = isWebsite
        ? { target: "_blank", rel: "noreferrer" }
        : {};

    return (
        <div className="row g-0">
            <div className="col">
                <a
                    href={href}
                    className={className}
                    style={style}
                    {...linkProps}
                >
                    {text}{suffix}
                </a>
            </div>
        </div>
    );
};

// PropTypes for better documentation and type checking
CallToActionButton.propTypes = {
    url: PropTypes.string,
    text: PropTypes.string.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
    suffix: PropTypes.string,
    websiteRegExp: PropTypes.instanceOf(RegExp),
    normalizeUrl: PropTypes.func
};

export default CallToActionButton;