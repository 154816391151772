import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'

import { Addresses, Loading } from "_components";
import { fetchWrapper } from '_helpers';
import {
    About,
    CompanyCurrency,
    CompanyEmails,
    CompanyIndustry,
    CompanyLogo,
    CompanyName,
    CompanyPhoneNumbers,
    CompanyWebsite,
    Slogan,
    Socials
} from ".";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { BusinessCardPreview } from "../user/BusinessCardPreview";
import { Wallets } from "vendor/Wallets";
import { isMobile } from 'react-device-detect';

export function Profile() {
    const authUser = useSelector(x => x.auth.user);
    const [company, setCompany] = useState();
    const [showPreview, setShowPreview] = useState(false);
    const [loading, setLoading] = useState(true);
    const [invitationCode, setInvitationCode] = useState(null);

    useEffect(() => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/profile/company").then(response => {
            setCompany(response.data);
            setLoading(false);
            getInvitationCode();
        });
    }, [0])

    function getInvitationCode() {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/profile/lead-invitation").then(response => {
            setInvitationCode(response.data.value);
        });
    }

    return (
        <>
            {loading && <Loading />}
            {company &&
                <>
                    <div className="row justify-content-center border-bottom mt-4">
                        <div className="col-lg-8 col-xl-8">
                            {isMobile &&
                                <div className="row"> 
                                    <div className="col">
                                        <button type='button' className="btn" onClick={() => setShowPreview(true)}><FontAwesomeIcon icon={faEye} /> Preview</button>
                                    </div>
                                </div>
                            }
                            <div className="row">
                                <div className="col-lg-3 text-center">
                                    <CompanyLogo company={company} />
                                </div>
                                <div className="col-lg-6">
                                    <CompanyName company={company} />
                                    <p className="mb-0">Channel name</p>
                                    <p className="text-secondary mb-0 fs-6">The channel provides direct access to your account in the form</p>
                                    <p className="mb-3"><a href="#">https://abccrm.app/{authUser.channelName}</a></p>
                                    <CompanyPhoneNumbers />
                                    <CompanyWebsite company={company} />
                                    <Addresses
                                        loadUrl="/profile/company/addresses"
                                        editUrl="/accountowner/company/address"
                                        editAllowed={authUser.accountOwner}
                                        description="Enter the physical addresses of your business."
                                    />
                                    <CompanyEmails />
                                    <CompanyIndustry company={company} />
                                    <CompanyCurrency company={company} />
                                    <About company={company} />
                                    <Slogan company={company} />
                                    <Socials company={company} />
                                </div>
                                {!isMobile &&
                                    <div className="col-lg-3">
                                        <div className="row">
                                            <div className="col">
                                                <button type='button' className="btn" onClick={() => setShowPreview(true)}><FontAwesomeIcon icon={faEye} /> Preview</button>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <Wallets />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <BusinessCardPreview
                        showPreview={showPreview}
                        onHide={() => setShowPreview(false)}
                        channelName={authUser.channelName}
                        invitationCode={invitationCode}
                    />
                </>
            }
        </>
    )

}