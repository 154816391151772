import { Loading, Paginate } from "_components";
import { fetchWrapper } from "_helpers";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NoResults } from "_components";

export function List({leadId}) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [items, setItems] = useState([]);

    useEffect(() => {
        loadDetails();
    }, [leadId, page])

    const loadDetails = () => {
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/vendor/lead/" + leadId + "/interactions", {"pageNumber": page, "pageSize": 5}).then(response => {
            setItems(response.data.content);
            setTotalElements(response.data.totalElements)
            setTotalPages(response.data.totalPages);
            setLoading(false)
        });
    }

    const handlePageClick = (event) => {
        setPage(event.selected);
    };

    return (
        <>
            <div className="row mt-2" style={{backgroundColor: "#e2e2e2"}}>
                <div className="col">
                    <h6 className="mt-1 mb-1"><strong>Event log{totalElements > 0 && <span className="ms-2">({totalElements})</span>}</strong></h6>  
                </div>
            </div>
            {loading === true ? <Loading /> : 
                items.length === 0 ?
                    <div className="row justify-content-center mt-2">
                        <div className="col">
                            <NoResults />
                        </div>
                    </div>
                    :
                    <div className="row mt-2">
                        <div className="col">
                            {items.map((item, index) => 
                                <div key={index} >
                                    <p className="mb-0">
                                        {moment(item.creationDate).format('DD/MM/YYYY')}
                                        &nbsp;|&nbsp;{t("LEADINTERACTIONTYPE." + item.leadInteractionType + ".KEY")}
                                        &nbsp;|&nbsp;"{item.title}"
                                    </p>
                                    {index < items.length -1 && <hr className="dotted mt-1 mb-2"></hr>}
                                </div>
                            )}
                        </div>
                    </div>
            }
            <Paginate totalPages={totalPages} handlePageClick={handlePageClick}/>
        </>
    )

}