import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { useEffect, useState } from "react";
import { ConfirmationDialog } from "_components";
import { fetchWrapper } from "_helpers";

export function LeadHeader({ lead, handleBackClick }) {
    const [showBackButton, setShowBackButton] = useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);

    useEffect(() => {
        if (window.screen.width < 850) {
            setShowBackButton(true);
        }
    }, [window.screen.width]);

    // Determine background and text colors based on phoneNumberVerified
    const backgroundColor = lead.phoneNumberVerified === false ? "#EBC62A" : "black";
    const textColor = lead.phoneNumberVerified === false ? "black" : "white";

    const handleInviteClick = () => {
        setShowConfirmDialog(true);
    };

    const handleConfirmInvite = () => {
        // Add logic to send invite
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/lead/" + lead.id + "/send-confirmation").then(response => {
            setShowConfirmDialog(false);
        });

    };

    const handleCancelInvite = () => {
        setShowConfirmDialog(false);
    };

    return (
        <>
            <div className="row" style={{ backgroundColor, color: textColor, height: "6rem" }}>
                <div className="col">
                    <div className="d-flex justify-content-between align-items-center mt-2"
                        style={{ height: (!showBackButton && lead.phoneNumberVerified !== false) ? '24px' : '24px' }}>
                        <div>
                            {showBackButton &&
                                <span className="badge badge-sm rounded-pill mt-0">
                                    <a className="cursor" style={{ color: textColor }} onClick={handleBackClick}>
                                        <FontAwesomeIcon icon={faArrowLeft} /> Back
                                    </a>
                                </span>
                            }
                        </div>
                        <div>
                            {lead.phoneNumberVerified === false &&
                                <button
                                    className="btn btn-sm btn-dark rounded-pill px-3 min-width-100"
                                    onClick={handleInviteClick}
                                    style={{ minWidth: "100px" }}
                                >
                                    Invite
                                </button>
                            }
                        </div>
                    </div>
                    <h4 className="mb-0">{lead.firstName + " " + lead.lastName}</h4>
                    <p className="fs-6" style={{ color: textColor }}>
                        {lead.phoneNumberVerified === false ? "Began subscribing on " : "Subscribed on "}
                        {moment(lead.creationDate).format('MMM Do YYYY')}
                    </p>
                </div>
            </div>

            {/* Confirmation Dialog */}
            {showConfirmDialog && (
                <ConfirmationDialog
                    message={"Send Invitation"}
                    bodyText={`Are you sure you want to send an invitation to ${lead.firstName} ${lead.lastName}?`}
                    show={showConfirmDialog}
                    handleConfirm={handleConfirmInvite}
                    handleCancel={handleCancelInvite}
                />
            )}
        </>
    );
}