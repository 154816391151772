import React from 'react';
import { useSelector } from 'react-redux';
import { AdminDashboard } from 'admin';
import { VendorDashboard } from 'vendor';

const ROLES = {
    ADMIN: 'ROLE_ADMIN',
    ACCOUNT_OWNER: 'ROLE_ACCOUNT_OWNER',
    VENDOR: 'ROLE_VENDOR'
};

function Home() {
    const { user: authUser } = useSelector((state) => state.auth);

    const hasRole = (role) => authUser?.roles?.includes(role) || false;

    // Determine which dashboard to show based on user role
    const renderDashboard = () => {
        if (hasRole(ROLES.ADMIN)) {
            return <AdminDashboard />;
        }

        if (hasRole(ROLES.ACCOUNT_OWNER) || hasRole(ROLES.VENDOR)) {
            return <VendorDashboard />;
        }

        return <div>No dashboard available for your role</div>;
    };

    return (
        <>
            {renderDashboard()}
        </>
    );
}

export default Home;
export { Home };