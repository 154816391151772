import { TextInputField } from '_components';
import { constants } from '_helpers';
import React from 'react';
import * as Yup from 'yup';

// Separate validation schema for Call To Action
export const callToActionValidationSchema = Yup.object({
    callToActionText: Yup.string().max(100, 'Must be 100 characters or less'),
    callToActionURL: Yup.string()
        .max(100, 'Must be 100 characters or less')
        .test('is-valid-url-or-phone', 'Must be a valid URL or phone number', (value) => {
            if (!value) return true;
            const isValidUrl = constants.websiteRegExp.test(value);
            const isValidPhone = constants.phoneRegExp.test(value);
            const isValidCAPhone = constants.canadaPhoneRegExp.test(value);
            return isValidUrl || isValidPhone || isValidCAPhone;
        })
});

const CallToAction = ({ register, errors }) => {
    return (
        <div className="call-to-action-section">
            <h4 className="mb-1 mt-3">Call To Action</h4>

            <TextInputField
                register={register}
                label="Link text"
                name="callToActionText"
                error={errors.callToActionText}
                maxLength="100"
            />

            <TextInputField
                register={register}
                label="URL or phone number"
                name="callToActionURL"
                error={errors.callToActionURL}
                maxLength="100"
            />
        </div>
    );
};

export default CallToAction;