import { useState, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { AudienceForm } from "./AudienceForm";

export function AddAudienceButton({ onAudienceSaved }) {
    const [showModal, setShowModal] = useState(false);
    
    const handleAddClick = useCallback(() => {
        setShowModal(true);
    }, []);
    
    const handleCloseModal = useCallback(() => {
        setShowModal(false);
    }, []);
    
    const handleAudienceSaved = useCallback(() => {
        setShowModal(false);
        if (onAudienceSaved) {
            onAudienceSaved();
        }
    }, [onAudienceSaved]);
    
    return (
        <>
            <button onClick={handleAddClick} className="btn" aria-label="Add audience">
                <img src="circle-plus-regular.svg" width={20} alt="Add audience" />
            </button>
            
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Audience</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AudienceForm callback={handleAudienceSaved} />
                </Modal.Body>
            </Modal>
        </>
    );
}