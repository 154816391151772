import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

import { history } from '_helpers';
import { Nav } from '../Nav';

export { PrivateRoute };

function PrivateRoute({ children }) {
    const { user: authUser } = useSelector(x => x.auth);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Add a window resize listener to update state when screen size changes
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if (!authUser) {
        // not logged in so redirect to login page with the return url
        return <Navigate to="/login" state={{ from: history.location }} />
    }

    // Determine which footer content to show based on current window width
    const showFullFooter = windowWidth >= 768; // md breakpoint is 768px

    // authorized so return child components
    return (
        <div className="app-container d-flex flex-column min-vh-100">
            <Nav />
            <div className="container flex-grow-1 mb-4">
                {children}
            </div>
            <footer className="mt-auto">
                <div className="d-flex flex-wrap justify-content-center align-items-center py-3" style={{ backgroundColor: "#F2F3FA" }}>
                    {showFullFooter ? (
                        <div>
                            <p className="mb-0 text-muted text-center">Copyright Seascapes Technologies Inc. 2022 - 2025. All rights reserved.</p>
                        </div>
                    ) : (
                        <div>
                            <p className="mb-0 text-muted text-center">Copyright Seascapes Technologies Inc. 2022 - 2025.</p>
                            <p className="mb-0 text-muted text-center">All rights reserved.</p>
                        </div>
                    )}
                    <ul className="nav justify-content-center">
                        <li className="nav-item"><a href="/dashboard" className="nav-link px-2 text-muted">Home</a></li>
                        <li className="nav-item"><a href="https://abccrm.ca/terms" className="nav-link px-2 text-muted">Terms Of Use</a></li>
                        <li className="nav-item"><a href="https://abccrm.ca/contact" className="nav-link px-2 text-muted">Contact Us</a></li>
                    </ul>
                </div>
            </footer>
        </div>
    )
}