import { fetchWrapper } from "_helpers";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useReactToPrint } from 'react-to-print';
import { AudienceTitle, Loading } from "_components";
import Offer from "page/Offer";

export function PrintPrepare({ offerId, offerCode, show, cancelCallback }) {
    const { user: authUser } = useSelector(x => x.auth);
    const componentRef = useRef();
    const [loading, setLoading] = useState(true);
    const [audiences, setAudiences] = useState([]);
    const [invitationShortCode, setInvitationShortCode] = useState(null);
    const [selectedAudience, setSelectedAudience] = useState(null);
    const [selectedLandingPage, setSelectedLandingPage] = useState(null);
    const [isSafari, setIsSafari] = useState(false);

    const {
        register,
        formState
    } = useForm();
    const { errors } = formState;

    useEffect(() => {
        // Check if browser is Safari
        const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        setIsSafari(isSafariBrowser);
        
        loadAudiences();
        getLandingPageId();
    }, [offerId]);

    useEffect(() => {
        if (selectedLandingPage && selectedAudience) {
            fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/invite/lead/" + selectedAudience + "/" + selectedLandingPage).then(response => {
                console.log("short code " + response.data.shortCode);
                setInvitationShortCode(response.data.shortCode);
            });
        }
    }, [selectedAudience, selectedLandingPage]);

    function loadAudiences() {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/audiences/active").then(response => {
            setAudiences(response.data);
            setLoading(false);
        });
    }

    function getLandingPageId() {
        if (offerId !== undefined) {
            fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/offer/" + offerId + "/landing-page").then(response => {
                setSelectedLandingPage(response.data.value);
            });
        }
    }

    function onAudienceChanged(event) {
        setInvitationShortCode(null);
        setSelectedAudience(event.target.value);
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onBeforeGetContent: () => {
            // Ensure content is ready before printing
            return new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                }, 250);
            });
        },
    });

    // Print function with appropriate delay
    const printWithFix = () => {
        // Add a delay to ensure component is rendered
        setTimeout(() => {
            handlePrint();
        }, isSafari ? 500 : 300);
    };

    return (
        <>
            <Modal show={show} onHide={cancelCallback}>
                <Modal.Header closeButton>
                    <Modal.Title>Print Offer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading === true ? <Loading /> :
                        <div className="row justify-content-center">
                            <div className="col">
                                <div className="form-group mb-4">
                                    <label htmlFor="audience" className="mb-1">Audience</label>
                                    <select id="audience" {...register('audience', { onChange: onAudienceChanged })} className={`form-select ${errors.audience ? 'is-invalid' : ''}`}>
                                        <option value="">Choose...</option>
                                        {audiences.map(audience => <option key={audience.id} value={audience.id}><AudienceTitle audience={audience} /></option>)}
                                    </select>
                                    <div className="invalid-feedback">{errors.audience?.message}</div>
                                </div>

                                <div className="d-grid gap-2 mb-3">
                                    <button onClick={printWithFix} disabled={invitationShortCode == null ? true : false} className="btn btn-lg btn-dark mt-3 rounded-pill">Print</button>
                                </div>
                            </div>
                        </div>
                    }
                </Modal.Body>
            </Modal>
            {invitationShortCode &&
                <div className="d-none d-print-block" style={{ width: '8.5in', height: '11in', overflow: 'hidden' }}>
                    <Offer ref={componentRef} code={offerCode} channelName={authUser.channelName} invitationCode={invitationShortCode} preview={true} />
                </div>
            }
        </>
    );
}