import {useNavigate, useParams} from 'react-router-dom';
import {useForm} from "react-hook-form";
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import {fetchWrapper} from '_helpers';
import { PasswordInputField, SubmitBar } from '_components';

export {PasswordReset}

function PasswordReset() {
    const navigate = useNavigate();
    const {token} = useParams();

    // form validation rules
    const validationSchema = Yup.object({
        password: Yup.string().required('Enter a new password').min(8, "Password must be at least 8 characters"),
        confirmPassword: Yup.string().oneOf(
            [
                Yup.ref('password'), null
            ],
            'Passwords must match'
        ),
    });
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    const {
        register,
        handleSubmit,
        formState,
        setError,
        getValues
    } = useForm(formOptions);
    const {errors, isSubmitting} = formState;

    const onSubmit = () => {
        const values = getValues();
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/password/reset/" + token, values).then(response => {
            if (response.status === 535) {
                setError("password", {
                    type: 'custom',
                    message: "Wrong password!"
                }, {shouldFocus: true});
            } else {
                navigate("/");
            }
        });
    }

    return (
        <>
            <div className="row g-0 justify-content-center">
                <div className="col-sm-6">
                    <div className="d-none d-lg-block d-md-block">
                        <img src="/login-optimized.png" alt="logo" className='img-fluid float-end' />
                    </div>
                    <div className='d-block d-sm-none' >
                        <img src="/login-narrow.png" alt="logo" className='img-fluid' />
                    </div>
                </div>
                <div className="col-sm-5">
                    <div className='row g-0'>
                        <div className='col-1 d-none d-lg-block'>&nbsp;</div>
                        <div className='col'>
                            <div className='mt-4 d-none d-lg-block'>&nbsp;</div>
                            <div className='mt-4 d-none d-lg-block'>&nbsp;</div>
                            <h3 className='text-center mt-4'><strong>Reset Password</strong></h3>
                            <div className='mt-4 d-none d-lg-block'>&nbsp;</div>
                            <div className='container'>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <PasswordInputField label="New Password" register={register} error={errors.password} />
                                    <PasswordInputField label="Retype Password" name="confirmPassword" register={register} error={errors.confirmPassword} />
                                    <SubmitBar labelConfirm="Save" cancelLink="/" isSubmitting={isSubmitting} />
                                </form>
                            </div>
                        </div>
                        <div className='col-3 d-none d-lg-block'>&nbsp;</div>
                    </div>
                </div>
                <div className="col"></div>
            </div>
            <div className="d-flex flex-wrap justify-content-center align-items-center py-3" style={{backgroundColor: "#F2F3FA"}}>
                <p className="mb-0 text-muted text-center">Copyright Seascapes Technologies Inc. 2022 - 2025. All rights reserved.</p>
                <ul className="nav justify-content-center">
                    <li className="nav-item"><a href="https://abccrm.ca/terms" className="nav-link px-2 text-muted">Terms Of Use</a></li>
                    <li className="nav-item"><a href="https://abccrm.ca/contact" className="nav-link px-2 text-muted">Contact Us</a></li>
                </ul>
            </div>
        </>
    )

}