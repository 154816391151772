import { Loading, QRCodePreview } from "_components";
import { fetchWrapper } from "_helpers";
import { useEffect, useState } from "react";

// Print Styles Component with all print-related styling
export function PrintStyles() {
  return (
    <style type="text/css">
      {`
        @media print {
          @page {
            size: letter;
            margin: 0;
          }
          
          html, body {
            width: 8.5in;
            height: 11in;
            margin: 0;
            padding: 0;
            overflow: hidden;
          }
          
          .container-scan-and-subcribe {
            width: 8.5in;
            height: 11in;
            page-break-inside: avoid;
            page-break-after: avoid;
            overflow: hidden;
          }
          
          /* Safari-specific fixes */
          @supports (-webkit-touch-callout: none) {
            html, body {
              -webkit-print-color-adjust: exact;
              print-color-adjust: exact;
              width: 100%;
              height: 100%;
            }
            
            .container-scan-and-subcribe {
              transform: scale(0.95);
              transform-origin: top center;
              width: 8.5in !important;
              height: auto !important;
              max-height: none !important;
              overflow: visible !important;
            }
            
            /* Ensure last row is visible */
            .container-scan-and-subcribe .bottom-image-row {
              position: relative !important;
              display: flex !important;
              visibility: visible !important;
              margin-top: 0.5rem !important;
            }
            
            /* Adjust image heights to ensure fitting */
            .img-container img {
              max-height: 22rem !important;
            }
            
            .card-img-top {
              max-height: 14rem !important;
            }
            
            /* Additional Safari print styles */
            body {
              -webkit-print-color-adjust: exact !important;
              print-color-adjust: exact !important;
            }
            
            img {
              -webkit-print-color-adjust: exact !important;
              print-color-adjust: exact !important;
            }
            
            .d-print-block {
              display: block !important;
              width: 8.5in !important;
              height: auto !important;
              overflow: visible !important;
              page-break-after: avoid !important;
            }
          }
        }
      `}
    </style>
  );
}

export function ScanAndSubscribe({ offer, pictures, channelName, invitationCode }) {
    const [loading, setLoading] = useState(true);
    const [profile, setProfile] = useState(null);
    const [content, setContent] = useState(null);
    const [pageUrl, setPageUrl] = useState(null);
    const [mainImage, setMainImage] = useState(null);
    const [leftImage, setLeftImage] = useState(null);
    const [rightImage, setRightImage] = useState(null);
    const [isSafari, setIsSafari] = useState(false);

    useEffect(() => {
        // Check if browser is Safari
        const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        setIsSafari(isSafariBrowser);
        
        if (offer !== undefined) {
            let content = JSON.parse(offer.content);
            setContent(content);
            setPageUrl(process.env.REACT_APP_BASE_URL + "/" + channelName + "/" + invitationCode);
        }
        loadPersonalDetails();
    }, [offer.code])

    useEffect(() => {
        if (pictures) {
            for (let index = 0; index < pictures.length; index++) {
                const picture = pictures[index];
                if (picture.info === 'mainImage') {
                    setMainImage(picture)
                } else if (picture.info === 'leftImage') {
                    setLeftImage(picture);
                } else if (picture.info === 'rightImage') {
                    setRightImage(picture);
                }
            }
        }
    }, [pictures])

    // Safari-specific optimizations
    useEffect(() => {
        // Safari-specific adjustments
        if (isSafari) {
            // Add a class to body for Safari-specific print styling
            document.body.classList.add('safari-browser');
            
            // Ensure images are loaded before printing
            const preloadImages = () => {
                if (mainImage) {
                    const img = new Image();
                    img.src = "/uploads/offer/" + mainImage.path;
                }
                if (leftImage) {
                    const img = new Image();
                    img.src = "/uploads/offer/" + leftImage.path;
                }
                if (rightImage) {
                    const img = new Image();
                    img.src = "/uploads/offer/" + rightImage.path;
                }
                if (profile && profile.profileImage) {
                    const img = new Image();
                    img.src = "/uploads/user/" + profile.profileImage;
                }
            };
            preloadImages();
            
            // Add additional Safari print styles dynamically
            const styleElement = document.createElement('style');
            styleElement.type = 'text/css';
            styleElement.id = 'safari-print-dynamic-styles';
            styleElement.innerHTML = `
                @media print {
                    @page { size: letter; margin: 0; }
                    
                    html, body {
                        height: auto !important;
                        overflow: visible !important;
                        -webkit-print-color-adjust: exact !important;
                    }
                    
                    .container-scan-and-subcribe {
                        transform: scale(0.95) !important;
                        transform-origin: top center !important;
                        height: auto !important;
                        max-height: none !important;
                    }
                    
                    .bottom-image-row {
                        display: flex !important;
                        visibility: visible !important;
                    }
                    
                    img {
                        -webkit-print-color-adjust: exact !important;
                    }
                }
            `;
            document.head.appendChild(styleElement);
            
            // Clean up on unmount
            return () => {
                document.body.classList.remove('safari-browser');
                const styleElem = document.getElementById('safari-print-dynamic-styles');
                if (styleElem) styleElem.remove();
            };
        }
    }, [isSafari, mainImage, leftImage, rightImage, profile]);

    const loadPersonalDetails = () => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/page/personal/" + channelName + "/offer/" + offer.code).then(response => {
            setProfile(response.data);
            setLoading(false);
        });
    }

    return (
        <>
            {/* Include the PrintStyles component */}
            <PrintStyles />
            
            {loading && <Loading />}
            {profile &&
                <>
                    <div className="container container-scan-and-subcribe montserrat-text">
                        <div className="row">
                            <div className="col">
                                <p className="mt-4 mb-0 ms-2">{content.companyWebsite}</p>
                                <p className="ms-2">{content.phoneNumber}</p>
                            </div>
                        </div>
                        <div className="row mt-3 mb-2 justify-content-center">
                            <div className="col text-center">
                                <h3>{content.welcomeMessage}</h3>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col text-center" >
                                {mainImage &&
                                    <div className="img-container" style={{ flex: "1", position: "relative", margin: "0.5rem" }}>
                                        <img 
                                            src={"/uploads/offer/" + mainImage.path} 
                                            style={{ 
                                                objectFit: "cover", 
                                                height: isSafari ? "20rem" : "23rem", 
                                                width: "100%" 
                                            }} 
                                            alt={mainImage.path} 
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="row mt-4 mb-4 justify-content-center">
                            <div className="col-4" style={{ borderRight: ".05rem solid" }}>
                                {content.info && <p className="mb-0 ms-2" style={{ whiteSpace: "pre-line" }}>{content.info}</p>}
                            </div>
                            <div className="col-4" style={{ borderRight: ".05rem solid" }}>
                                <h4 className="text-center mb-2">PLEASE SCAN & SUBSCRIBE</h4>
                                <div className="row justify-content-center">
                                    <div className="col-6 col-lg-6">
                                        {pageUrl &&
                                            <QRCodePreview value={pageUrl} maxWidth={80} />
                                        }

                                    </div>
                                </div>
                            </div>
                            <div className="col-4">
                                {profile &&
                                    <>
                                        <div className="row" >
                                            <div className="col">
                                                {profile.profileImage &&
                                                    <div className="text-center mt-3" >
                                                        <img src={"/uploads/user/" + profile.profileImage} alt={profile.profileImage} style={{ maxHeight: "5.5rem", borderRadius: ".475rem" }} />
                                                    </div>
                                                }
                                                <h4 className="mt-2 mb-0 text-center">{profile.firstName} {profile.lastName}</h4>
                                                <h5 className="mb-0 text-center">{profile.occupation}</h5>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="row g-0 justify-content-center bottom-image-row">
                            <div className="col-6 text-center">
                                {leftImage &&
                                    <div style={{ flex: "1", position: "relative", margin: "0.5rem" }}>
                                        <img src={"/uploads/offer/" + leftImage.path} alt={leftImage.path} className='card-img-top' style={{ objectFit: "cover", height: "15rem", width: "100%" }} />
                                    </div>
                                }
                            </div>
                            <div className="col-6 text-center">
                                {rightImage &&
                                    <div style={{ flex: "1", position: "relative", margin: "0.5rem" }}>
                                        <img src={"/uploads/offer/" + rightImage.path} alt={rightImage.path} className='card-img-top' style={{ objectFit: "cover", height: "15rem", width: "100%" }} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}