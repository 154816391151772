import { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { Loading, NoResults, Paginate } from "_components";
import { fetchWrapper } from "_helpers";
import { Audience } from "./Audience";
import { AddAudienceButton } from "./AddAudienceButton";

export function Audiences({ handleAudienceClicked, refreshTrigger }) {
    const { watch, getValues, setValue } = useForm({
        defaultValues: {
            pageSize: 10,
            showArchived: localStorage.getItem('showArchived') !== 'false' // Default to true unless explicitly set to false
        }
    });
    const searchPattern = watch('searchPattern');
    const [loading, setLoading] = useState(false);
    const [audiences, setAudiences] = useState([]);
    const [showArchived, setShowArchived] = useState(
        localStorage.getItem('showArchived') !== 'false' // Default to true unless explicitly set to false
    );
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);

    const loadAudiences = useCallback((pageNumber) => {
        setLoading(true);
        setValue("pageSize", 10, { shouldTouch: true });
        setValue("pageNumber", pageNumber, { shouldTouch: true });
        setValue("showArchived", showArchived);
        
        fetchWrapper.post(`${process.env.REACT_APP_API_URL}/vendor/audiences`, getValues())
            .then(response => {
                setAudiences(response.data.content);
                setTotalPages(response.data.totalPages);
            })
            .catch(error => {
                console.error("Failed to load audiences:", error);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [showArchived, setValue, getValues]);

    useEffect(() => {
        loadAudiences(page);
    }, [showArchived, page, loadAudiences]);

    // Add effect to listen for refreshTrigger changes
    useEffect(() => {
        if (refreshTrigger > 0) {
            // Reset to first page and refresh
            setPage(0);
            loadAudiences(0);
        }
    }, [refreshTrigger, loadAudiences]);

    const handlePageClick = useCallback((event) => {
        setPage(event.selected);
    }, []);

    const toggleShowArchived = useCallback(() => {
        const newValue = !showArchived;
        setShowArchived(newValue);
        // Persist the value to localStorage
        localStorage.setItem('showArchived', newValue.toString());
        setPage(0);
    }, [showArchived]);

    const handleAudienceSaved = useCallback(() => {
        setPage(0);
        loadAudiences(0);
    }, [loadAudiences]);

    return (
        <>
            <div className="row justify-content-center mt-3">
                <div className="col-10">
                    <h4 className="mt-2 mb-0">Audiences</h4>
                    <button 
                        onClick={toggleShowArchived} 
                        className="btn btn-link p-0" 
                        style={{fontSize: "smaller"}}
                    >
                        {showArchived ? 'Hide archived' : 'Show archived'}
                    </button>
                </div>
                <div className="col-2 text-end">
                    <AddAudienceButton onAudienceSaved={handleAudienceSaved} />
                </div>
            </div>
            
            {loading ? (
                <Loading />
            ) : audiences.length > 0 ? (
                <>
                    {audiences.map((audience, index) => (
                        <div key={audience.id || index}>
                            <Audience
                                audience={audience}
                                handleAudienceClicked={handleAudienceClicked}
                                callback={() => loadAudiences(page)}
                            />
                            {index < audiences.length - 1 && <div className="border-bottom"></div>}
                        </div>
                    ))}
                </>
            ) : (
                <NoResults />
            )}
            
            <Paginate totalPages={totalPages} handlePageClick={handlePageClick} />
        </>
    );
}