import { Tab, Tabs } from "react-bootstrap";
import { CompanyProfile } from "./company";
import { UserProfile } from "./user";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Wallets } from "vendor/Wallets";
import { isMobile } from 'react-device-detect';

export { Profile }

function Profile() {
    const authUser = useSelector((state) => state.auth.user);
    const localStorageKey = "tab-profile-active";

    const [activeKey, setActiveKey] = useState(() => {
        const storedTab = localStorage.getItem(localStorageKey);
        return storedTab ? storedTab : authUser.accountType === "PERSONAL" ? "personal" : "company";
    });

    const handleSelect = (key) => {
        setActiveKey(key);
        localStorage.setItem(localStorageKey, key);
    };

    useEffect(() => {
        localStorage.setItem(localStorageKey, activeKey);
    }, [activeKey]);

    return (
        <>
            <div className="row justify-content-center">
                <div className="col text-center">
                    <h3 className="mt-4">My Profiles</h3>
                    {isMobile && <Wallets height={34}/>}
                </div>
            </div>
            <div className="mt-4 mb-4"></div>
            <Tabs activeKey={activeKey} className="mb-3 justify-content-center" onSelect={handleSelect}>
                <Tab eventKey="company" title="COMPANY">
                    <CompanyProfile />
                </Tab>
                <Tab eventKey="personal" title="PERSONAL">
                    <UserProfile />
                </Tab>
            </Tabs>
        </>
    );
}

export default Profile;
