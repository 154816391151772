import { useState, useCallback } from "react";
import { Modal } from "react-bootstrap";
import { LeadForm } from "./LeadForm";

export function AddLeadButton({ audienceId, onLeadSaved }) {
    const [showModal, setShowModal] = useState(false);
    
    const handleAddClick = useCallback(() => {
        setShowModal(true);
    }, []);
    
    const handleCloseModal = useCallback(() => {
        setShowModal(false);
    }, []);
    
    const handleLeadSaved = useCallback(() => {
        setShowModal(false);
        if (onLeadSaved) {
            onLeadSaved();
        }
    }, [onLeadSaved]);
    
    return (
        <>
            <button onClick={handleAddClick} className="btn" aria-label="Add lead">
                <img src="circle-plus-regular.svg" width={20} alt="Add lead" />
            </button>
            
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Lead</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LeadForm audienceId={audienceId} callback={handleLeadSaved} />
                </Modal.Body>
            </Modal>
        </>
    );
}