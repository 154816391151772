import { faCheckCircle, faEnvelope, faPhone, faWarning, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { AudienceTitle, Loading, NoResults, Paginate } from "_components";
import { fetchWrapper } from "_helpers";
import { SendToGroup } from "./SendToGroup";
import { AddLeadButton } from "./AddLeadButton";
import { AdvancedSearch } from "./AdvancedSearch";

export function Leads({ audience, showLeadsSection, handleClicked, handleBackClick, onLeadSaved }) {
    const { register, watch, getValues, setValue } = useForm();
    const searchPattern = watch('searchPattern');
    const interactionsCount = watch('interactionsCount');
    const lastInteractionWithin = watch('lastInteractionWithin');
    const offerId = watch('offerId');
    const offerStateId = watch('offerStateId');
    const tags = watch('tags');

    const [loading, setLoading] = useState(false);
    const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
    const [leads, setLeads] = useState([]);
    const [excludeLeadIds, setExcludeLeadIds] = useState(new Set());
    const [includeLeadIds, setIncludeLeadIds] = useState(new Set());
    const [selectAll, setSelectAll] = useState(true);
    const [deselectAll, setDeselectAll] = useState(false);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [offers, setOffers] = useState([]);

    const loadLeads = useCallback((pageToLoad = page) => {
        setLoading(true);
        setValue("pageSize", 10, { shouldTouch: true });
        setValue("pageNumber", pageToLoad, { shouldTouch: true });

        fetchWrapper.post(`${process.env.REACT_APP_API_URL}/vendor/audience/${audience.id}/leads`, getValues())
            .then(response => {
                const content = response.data.content;
                setLeads(content);
                setTotalPages(response.data.totalPages);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [audience, getValues, setValue]);

    const loadOffers = useCallback(() => {
        fetchWrapper.post(`${process.env.REACT_APP_API_URL}/vendor/offers`, { "searchPattern": "" })
            .then(response => {
                setOffers(response.data.content);
            });
    }, []);

    const clearFilter = useCallback(() => {
        setValue("lastInteractionWithin", "");
        setValue("interactionsCount", "");
        setValue("offerId", "");
        setValue("offerStateId", "");
    }, [setValue]);

    const resetSelections = useCallback(() => {
        setExcludeLeadIds(new Set());
        setIncludeLeadIds(new Set());
    }, []);

    const selectAllLeads = useCallback(() => {
        setSelectAll(true);
        setDeselectAll(false);
        resetSelections();
    }, [resetSelections]);

    const deselectAllLeads = useCallback(() => {
        setSelectAll(false);
        setDeselectAll(true);
        resetSelections();
    }, [resetSelections]);

    const toggleAdvancedSearch = useCallback(() => {
        setShowAdvancedSearch(prevState => !prevState);
    }, []);

    const handlePageClick = useCallback((event) => {
        setPage(event.selected);
    }, []);

    const handleChecked = useCallback((leadId) => {
        if (selectAll) {
            setExcludeLeadIds(prevIds => {
                const newIds = new Set(prevIds);
                if (newIds.has(leadId)) {
                    newIds.delete(leadId);
                } else {
                    newIds.add(leadId);
                }
                return newIds;
            });
        } else if (deselectAll) {
            setIncludeLeadIds(prevIds => {
                const newIds = new Set(prevIds);
                if (newIds.has(leadId)) {
                    newIds.delete(leadId);
                } else {
                    newIds.add(leadId);
                }
                return newIds;
            });
        }
    }, [selectAll, deselectAll]);

    const handleLeadSaved = useCallback(() => {
        setPage(0);
        loadLeads(0);
        
        // Also call the parent's onLeadSaved if provided
        if (onLeadSaved) {
            onLeadSaved();
        }
    }, [loadLeads, onLeadSaved]);

    // Effect: Reset and load data when audience changes
    useEffect(() => {
        if (audience !== null) {
            setPage(0);
            loadOffers();
            clearFilter();
            resetSelections();
            setSelectAll(true);
            setDeselectAll(false);
        }
    }, [audience, clearFilter, loadOffers, resetSelections]);

    // Effect: Load leads when page or audience changes
    useEffect(() => {
        if (audience !== null) {
            loadLeads(page);
        }
    }, [audience, loadLeads, page]);

    // Effect: Reset page and load leads when filters change
    useEffect(() => {
        if (audience !== null) {
            setPage(0);
            loadLeads(0);
        }
    }, [audience, loadLeads, searchPattern, interactionsCount, lastInteractionWithin, offerId, offerStateId, tags]);

    const isLeadChecked = useCallback((leadId) => {
        if (selectAll) {
            return !excludeLeadIds.has(leadId);
        } else if (deselectAll) {
            return includeLeadIds.has(leadId);
        }
        return false;
    }, [excludeLeadIds, includeLeadIds, selectAll, deselectAll]);

    const renderLeadItem = useCallback((lead) => (
        <div key={lead.id}>
            <div className="row g-0 mt-2 mb-2">
                <div className="col-7">
                    <input
                        type="checkbox"
                        className="form-check-input me-2"
                        style={{ marginTop: "0.70rem" }}
                        checked={isLeadChecked(lead.id)}
                        onChange={() => handleChecked(lead.id)}
                    />
                    <button
                        className="btn btn-link ps-0"
                        style={{ textAlign: 'left' }}
                        onClick={() => handleClicked(lead)}
                    >
                        <p className='mb-0'>{`${lead.firstName} ${lead.lastName}`}</p>
                    </button>
                    <p className="text-secondary mb-0 fs-6">
                        {lead.phoneNumber}{lead.email && `, ${lead.email}`}
                    </p>
                </div>
                <div className="col-5">
                    <div className="mt-2">
                        <span
                            className={`btn-sm-like ${lead.channelsVerified > 0 ? 'text-bg-success' : 'text-bg-warning'} shadow me-1`}
                            style={{ width: "4rem" }}
                        >
                            <FontAwesomeIcon
                                icon={lead.channelsVerified > 0 ? faCheckCircle : faWarning}
                                className="me-1"
                            />
                            <strong>{`${lead.channelsVerified}/${lead.channelsCount}`}</strong>
                        </span>
                        <div style={{ display: "inline" }}>
                            <a
                                href={`tel:${lead.phoneNumber}`}
                                className="btn btn-light btn-sm btn-sm-dashboard shadow me-1"
                            >
                                <FontAwesomeIcon icon={faPhone} />
                            </a>
                        </div>
                        <div style={{ display: "inline" }}>
                            {lead.email && (
                                <a
                                    href={`mailto:${lead.email}`}
                                    className="btn btn-light btn-sm btn-sm-dashboard me-1 shadow"
                                >
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="border-bottom"></div>
        </div>
    ), [handleChecked, handleClicked, isLeadChecked]);

    const renderLeadList = () => {
        if (loading) {
            return <Loading />;
        }

        if (leads.length === 0) {
            return <NoResults />;
        }

        return leads.map(renderLeadItem);
    };

    const renderSelectionControls = useCallback(() => (
        <div className="float-start">
            <a onClick={selectAllLeads} className="cursor">Select All</a>
            &nbsp;/&nbsp;
            <a onClick={deselectAllLeads} className="cursor">Deselect All</a>
        </div>
    ), [selectAllLeads, deselectAllLeads]);

    if (!audience) {
        return (
            <div className="px-4 py-5 my-5 text-center">
                <div className="col-lg-6 mx-auto">
                    <p>Select the Audience to see the leads.</p>
                </div>
            </div>
        );
    }

    return (
        <>
            <div className="row justify-content-center mt-3">
                <div className="col-4 d-flex align-items-center">
                    <h4 className="mt-2 me-2 mb-0">Leads</h4>
                    <AddLeadButton audienceId={audience?.id} onLeadSaved={handleLeadSaved} />
                </div>
                <div className="col-8">
                    <input
                        type="text"
                        className="form-control rounded-pill"
                        name="searchPattern"
                        {...register('searchPattern')}
                        placeholder="Search leads"
                    />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <span className="badge badge-sm rounded-pill text-bg-secondary">
                        {showLeadsSection && (
                            <>
                                <AudienceTitle audience={audience} />
                                <a
                                    className="ms-2 cursor"
                                    style={{ color: "white" }}
                                    onClick={handleBackClick}
                                >
                                    <FontAwesomeIcon icon={faX} />
                                </a>
                            </>
                        )}
                    </span>
                    <div className="float-end">
                        <a onClick={toggleAdvancedSearch} className="cursor">Filtering</a>
                    </div>
                </div>
            </div>

            {leads.length > 0 && (
                <div className="row mb-2">
                    <div className="col">
                        {!showAdvancedSearch && renderSelectionControls()}
                        <div className="float-end">
                            {audience && (
                                <SendToGroup
                                    selectedAll={selectAll}
                                    deselectedAll={deselectAll}
                                    audienceId={audience.id}
                                    includeLeadIds={includeLeadIds}
                                    excludeLeadIds={excludeLeadIds}
                                    filterValues={name => getValues(name)}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}

            {showAdvancedSearch && (
                <AdvancedSearch
                    register={register}
                    watch={watch}
                    setValue={setValue}
                    clearFilter={clearFilter}
                    renderSelectionControls={renderSelectionControls}
                    offers={offers}
                />
            )}

            {renderLeadList()}

            <Paginate totalPages={totalPages} handlePageClick={handlePageClick} />
        </>
    );
}