import { constants, fetchWrapper } from "_helpers";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { SubmitBar, TextInputField } from "_components";

/**
 * MeetingInvite component for creating and editing meeting invitations
 * @param {Object} props - Component props
 * @param {Object} [props.offer] - Existing offer data for editing
 * @param {string} [props.selectedTemplateId] - Template ID for new offers
 */
export function MeetingInvite({ offer, selectedTemplateId }) {
    const navigate = useNavigate();

    // Form validation schema
    const validationSchema = useMemo(() => 
        Yup.object({
            title: Yup.string()
                .required('Title is required field')
                .max(100, 'Title cannot exceed 100 characters'),
            description: Yup.string()
                .max(1000, 'Message cannot exceed 1000 characters'),
            callToActionURL: Yup.string()
                .required('Calendar URL is required')
                .matches(
                    constants.websiteRegExp, 
                    { message: "Calendar URL must be valid", excludeEmptyString: true }
                )
        }),
    []);

    // Initialize form with validation
    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setValue,
        getValues,
        reset
    } = useForm({
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true,
        defaultValues: {
            title: '',
            description: '',
            callToActionURL: ''
        }
    });

    // Load offer data when editing
    useEffect(() => {
        if (offer) {
            reset({
                title: offer.title || '',
                description: offer.description || '',
                callToActionURL: offer.callToActionURL || ''
            });
        }
    }, [offer, reset]);

    // Form submission handler
    const onSubmit = async () => {
        try {
            const formData = new FormData();
            const offerData = {
                title: getValues('title'),
                description: getValues('description'),
                callToActionURL: getValues('callToActionURL')
            };

            if (!offer) {
                // Creating a new offer
                offerData.templateId = selectedTemplateId;
            }

            formData.append("offer", JSON.stringify(offerData));

            const endpoint = offer 
                ? `${process.env.REACT_APP_API_URL}/vendor/offer/${offer.id}`
                : `${process.env.REACT_APP_API_URL}/vendor/offer`;

            const response = await fetchWrapper.postFormData(endpoint, formData);
            
            if (response.status === 200) {
                navigate("/offers");
            } else {
                // Handle non-200 responses
                console.error("Error submitting form:", response);
                // Could add toast notification here
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            // Could add error handling UI here
        }
    };

    return (
        <div className="row justify-content-center">
            <div className="col-lg-5">
                <form onSubmit={handleSubmit(onSubmit)} noValidate>
                    <TextInputField 
                        register={register} 
                        label="Title" 
                        name="title" 
                        error={errors.title} 
                        maxLength={100}
                    />
                    
                    <TextInputField 
                        register={register} 
                        label="Message" 
                        name="description" 
                        error={errors.description} 
                        textarea={true}
                    />
                    
                    <TextInputField 
                        register={register} 
                        label="Calendar URL" 
                        name="callToActionURL" 
                        error={errors.callToActionURL}
                    />
                    
                    <SubmitBar 
                        isSubmitting={isSubmitting} 
                        labelConfirm={"Save"} 
                        cancelLink="/offers" 
                    />
                </form>
            </div>
        </div>
    );
}