import { useState, useEffect, useCallback } from "react";
import { constants, fetchWrapper } from "_helpers";
import {
    RealEstateOfferTemplate,
    ImagePreview,
    EventOfferTemplate,
    MeetingInviteOfferTemplate,
    ScanAndSubscribeOfferTemplate
} from "./templates";

// Template card component for better organization
const TemplateCard = ({ template, index, onSelect, onPreview, hoverState, onMouseEnter, onMouseLeave }) => {
    return (
        <div className="row justify-content-center border-bottom py-3">
            {/* Image column - on mobile: top and centered; on desktop: left side */}
            <div className="col-12 col-md-4 order-0 order-md-0 mb-3 mb-md-0 text-center text-md-start">
                <img
                    src={`/uploads/offer/${template.thumbnail}`}
                    alt={template.title}
                    className="offer-template-thumbnail img-fluid"
                />
            </div>
            
            {/* Content column */}
            <div className="col-12 col-md d-flex flex-column">
                <h4 className="mb-1">{template.title}</h4>
                <p className="text-secondary mb-2 fs-6">{template.description}</p>

                <ChannelsDisplay template={template} />

                <p className="mb-2">
                    CTA: {template.cta || '-'}
                </p>

                <div className="mt-auto mb-1 d-flex align-items-center">
                    <button
                        onClick={() => onSelect(template)}
                        className="btn btn-dark btn-sm rounded-pill"
                        onMouseEnter={() => onMouseEnter(index)}
                        onMouseLeave={() => onMouseLeave(index)}
                        style={{
                            backgroundColor: hoverState ? '#198754' : 'black',
                            borderColor: hoverState ? '#198754' : 'black',
                            transition: 'background-color 0.2s, border-color 0.2s'
                        }}
                    >
                        Select
                    </button>
                </div>
            </div>
        </div>
    );
};

// Channels display component
const ChannelsDisplay = ({ template }) => {
    // Extract channel values
    const { mobileChannel, webChannel, printChannel } = template;

    // Create array of active channels
    const activeChannels = [];
    if (mobileChannel) activeChannels.push('Mobile');
    if (webChannel) activeChannels.push('Web');
    if (printChannel) activeChannels.push('Print');

    // Join channels with commas
    const channelsText = activeChannels.join(', ');

    return (
        <p className="mb-2">
            <strong>Channels:</strong> {channelsText || 'None'}
        </p>
    );
};

export function New() {
    // State management
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [hoverStates, setHoverStates] = useState([]);
    const [templatePreviewDialog, setTemplatePreviewDialog] = useState({
        show: false,
        imageSrc: null,
        mobileImageSrc: null
    });

    // Handle mouse events for hover effects
    const handleMouseEnter = (index) => {
        setHoverStates(prev => {
            const newHoverStates = [...prev];
            newHoverStates[index] = true;
            return newHoverStates;
        });
    };

    const handleMouseLeave = (index) => {
        setHoverStates(prev => {
            const newHoverStates = [...prev];
            newHoverStates[index] = false;
            return newHoverStates;
        });
    };

    // Handle template selection
    const handleSelectTemplate = (template) => {
        setSelectedTemplate(template);
        setHoverStates(Array(templates.length).fill(false));
    };

    // Open template preview dialog
    const handleOpenPreview = (imageSrc, mobileImageSrc) => {
        setTemplatePreviewDialog({
            show: true,
            imageSrc,
            mobileImageSrc
        });
    };

    // Close template preview dialog
    const handleClosePreview = () => {
        setTemplatePreviewDialog({ show: false });
    };

    // Load templates from API
    const loadTemplates = useCallback(() => {
        setIsLoading(true);
        setError(null);

        fetchWrapper.post(`${process.env.REACT_APP_API_URL}/vendor/offer-templates`, {
            "pageNumber": 0,
            "pageSize": 30
        })
            .then(response => {
                setTemplates(response.data);
                // Initialize hover states array with false values
                setHoverStates(Array(response.data.length).fill(false));
                setIsLoading(false);
            })
            .catch(err => {
                setError("Failed to load templates. Please try again later.");
                setIsLoading(false);
                console.error("Error loading templates:", err);
            });
    }, []);

    // Load templates on component mount
    useEffect(() => {
        loadTemplates();
    }, [loadTemplates]);

    // Render the appropriate template form based on selected template ID
    const renderTemplateForm = (templateId) => {
        switch (templateId) {
            case constants.offerRealEstateTemplateId:
                return <RealEstateOfferTemplate selectedTemplateId={templateId} />;
            case constants.offerEventTemplateId:
                return <EventOfferTemplate selectedTemplateId={templateId} />;
            case constants.offerMeetingIvinteTemplateId:
                return <MeetingInviteOfferTemplate selectedTemplateId={templateId} />;
            case constants.offerScanAndSubscribeTemplateId:
                return <ScanAndSubscribeOfferTemplate selectedTemplateId={templateId} />;
            default:
                return <div className="alert alert-warning">Unknown template type</div>;
        }
    };

    return (
        <div className="container-fluid py-4">
            {selectedTemplate === null ? (
                // Template selection view
                <>
                    <div className="row justify-content-center">
                        <div className="col-lg-8 text-center">
                            <h3 className="mt-3 mb-0">Create New Offer</h3>
                            <h5 className="text-secondary mt-2 mb-4">Choose the template and complete the form</h5>
                        </div>
                    </div>

                    {isLoading ? (
                        <div className="row justify-content-center">
                            <div className="col-lg-6 text-center py-5">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <p className="mt-3">Loading templates...</p>
                            </div>
                        </div>
                    ) : error ? (
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="alert alert-danger" role="alert">
                                    {error}
                                    <button
                                        className="btn btn-sm btn-outline-danger ms-3"
                                        onClick={loadTemplates}
                                    >
                                        Retry
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                {templates.length === 0 ? (
                                    <div className="alert alert-info">No templates available</div>
                                ) : (
                                    templates.map((template, index) => (
                                        <TemplateCard
                                            key={template.id}
                                            template={template}
                                            index={index}
                                            onSelect={handleSelectTemplate}
                                            onPreview={handleOpenPreview}
                                            hoverState={hoverStates[index]}
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}
                                        />
                                    ))
                                )}
                            </div>
                        </div>
                    )}
                </>
            ) : (
                // Template form view
                <>
                    <div className="row justify-content-center mb-4">
                        <div className="col-lg-8">
                            <div className="d-flex align-items-center">
                                <button
                                    onClick={() => setSelectedTemplate(null)}
                                    className="btn btn-outline-dark btn-sm rounded-pill me-3"
                                >
                                    <i className="fas fa-arrow-left me-1"></i>
                                    Back to Templates
                                </button>
                                <h3 className="mb-0">{selectedTemplate.title}</h3>
                            </div>
                            <hr className="mt-3" />
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col">
                            {renderTemplateForm(selectedTemplate.id)}
                        </div>
                    </div>
                </>
            )}

            {/* Template Preview Dialog */}
            <ImagePreview
                show={templatePreviewDialog.show}
                imageSrc={templatePreviewDialog.imageSrc}
                mobileImageSrc={templatePreviewDialog.mobileImageSrc}
                handleCancel={handleClosePreview}
            />
        </div>
    );
}