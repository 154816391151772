import { useState, useCallback, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import { fetchWrapper } from '_helpers';
import { ConfirmationCode } from "_components";
import { LeadOfferStates, ResolveConflict } from "lead";
import { SubscribeForm } from "./SubscribeForm";
import { ThankYouMessage } from "./ThankYouMessage";

export function Subscribe({
    label,
    callToAction,
    welcomeMessage,
    channelName,
    invitationCode
}) {
    const [subscribed, setSubscribed] = useState(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showManage, setShowManage] = useState(false);
    const formValuesRef = useRef(null);

    const handleSubmit = useCallback(async (formData, { getValues, setError }) => {
        try {
            // Store the current form values for later use
            formValuesRef.current = getValues();
            
            const url = `${process.env.REACT_APP_API_URL}/subscribe/${channelName}/${invitationCode}`;
            const response = await fetchWrapper.post(url, formData);

            switch (response.status) {
                case 200:
                    setSubscribed(response.data);
                    break;
                case 540:
                    setShowConfirmation(true);
                    break;
                case 541:
                    setShowManage(true);
                    break;
                case 536:
                    setError("phoneNumber", {
                        type: 'custom',
                        message: "Phone number is not valid! Please try again"
                    }, { shouldFocus: true });
                    break;
                case 553:
                default:
                    setError("phoneNumber", {
                        type: 'custom',
                        message: "Something went wrong. Please try again."
                    }, { shouldFocus: true });
                    break;
            }
        } catch (error) {
            setError("phoneNumber", {
                type: 'custom',
                message: "Something went wrong. Please try again."
            }, { shouldFocus: true });
        }
    }, [channelName, invitationCode]);

    const handleConfirmationComplete = useCallback((confirmationCode, status, data) => {
        setSubscribed(data);
    }, []);

    const handleConflictResolved = useCallback((status, data) => {
        setShowManage(false);
        setSubscribed(data);
    }, []);

    const handleCloseConfirmation = useCallback(() => {
        setShowConfirmation(false);
    }, []);

    const handleCloseManage = useCallback(() => {
        setShowManage(false);
    }, []);

    if (subscribed !== null) {
        return (
            <>
                <ThankYouMessage customMessage={welcomeMessage} />
                {subscribed.offerCode && (
                    <LeadOfferStates
                        channelName={channelName}
                        leadCode={subscribed.leadCode}
                        offerCode={subscribed.offerCode}
                    />
                )}
            </>
        );
    }

    return (
        <>
            {label && (
                <p className="text-center mb-0" style={{ fontSize: "large" }}>
                    <strong>{label}</strong>
                </p>
            )}
            {callToAction && <p className="mb-3">{callToAction}</p>}

            <SubscribeForm onSubmit={handleSubmit} />

            <Modal show={showConfirmation} onHide={handleCloseConfirmation}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm your identity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ConfirmationCode
                        url={`/subscribe/${channelName}/${invitationCode}`}
                        callback={handleConfirmationComplete}
                    />
                </Modal.Body>
            </Modal>

            <Modal show={showManage} onHide={handleCloseManage}>
                <Modal.Header closeButton>
                    <Modal.Title>Manage your identity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ResolveConflict
                        conflictValues={(fieldName) => formValuesRef.current?.[fieldName] || ""}
                        channelName={channelName}
                        invitationCode={invitationCode}
                        callback={handleConflictResolved}
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}