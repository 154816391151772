import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';

// Helpers and components
import { history } from '_helpers';
import { PrivateRoute } from '_components';

// Feature imports - alphabetized within groups
import { AdminCompany, Contacts } from 'admin';
import { ManageAccount, Vendors } from 'accountowner';
import { Home, PasswordManage, Profile } from 'home';
import { AccountOwnerInvitation, VendorInvitation } from 'invitation';
import { LeadEmailVerify, LeadMessage, LeadOffer, LeadProfile, Manage, Unsubscribed, LeadConfirmation } from 'lead';
import { EmailVerify, Login, PasswordForget, PasswordReset } from 'login';
import { Page } from 'page';
import { AccountOwnerRegistration, RegistrationSuccess, VendorRegistration } from 'registration';
import { EditOffer, NewOffer, Offers } from 'vendor/offers';

export function App() {
    // Initialize custom history object to allow navigation from 
    // anywhere in the react app (inside or outside components)
    history.navigate = useNavigate();
    history.location = useLocation();

    // Helper function to create private routes more concisely
    const privateRoute = (Component) => (
        <PrivateRoute>
            <Component />
        </PrivateRoute>
    );

    return (
        <Routes>
            {/* Dashboard/Home Route */}
            <Route path="/" element={privateRoute(Home)} />

            {/* Admin Routes */}
            <Route path="/admin/company/:companyId" element={privateRoute(AdminCompany)} />
            <Route path="/admin/invitation" element={privateRoute(AccountOwnerInvitation)} />
            <Route path="/admin/contacts" element={privateRoute(Contacts)} />

            {/* Profile Routes */}
            <Route path="/profile" element={privateRoute(Profile)} />
            
            {/* Vendor Routes */}
            <Route path="/invitation/vendor" element={privateRoute(VendorInvitation)} />
            <Route path="/vendors" element={privateRoute(Vendors)} />

            {/* Offer Routes */}
            <Route path="/offers" element={privateRoute(Offers)} />
            <Route path="/offer/new" element={privateRoute(NewOffer)} />
            <Route path="/offer/edit/:id" element={privateRoute(EditOffer)} />
            
            {/* Account Routes */}
            <Route path="/account/manage" element={privateRoute(ManageAccount)} />
            <Route path="/password/manage" element={privateRoute(PasswordManage)} />

            {/* Authentication Routes */}
            <Route path="/login" element={<Login />} />
            <Route path="/password/forget" element={<PasswordForget />} />
            <Route path="/password/reset/:token" element={<PasswordReset />} />
            <Route path="/register" element={<AccountOwnerRegistration />} />
            <Route path="/register/vendor" element={<VendorRegistration />} />
            <Route path="/register/success" element={<RegistrationSuccess />} />
            <Route path="/verify/email/:token" element={<EmailVerify />} />
            
            {/* Lead Routes */}
            <Route path="/m" element={<Manage />} />
            <Route path="/c/:channelName/:code" element={<LeadMessage />} />
            <Route path="/p/:channelName/:code" element={<LeadProfile />} />
            <Route path="/v/:channelName/:code" element={<LeadConfirmation />} />
            <Route path="/unsubscribed" element={<Unsubscribed />} />
            <Route path="/lead/email-verify/:code" element={<LeadEmailVerify />} />
            <Route path="/:channelName/lead/:leadCode/offer/:offerCode" element={<LeadOffer />} />
            
            {/* Page Routes */}
            <Route path="/:channelName/:invitationCode" element={<Page />} />
            
            {/* Fallback Route */}
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
}