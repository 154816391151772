import { faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AudienceTitle, ConfirmationDialog, Loading, NoResults } from "_components";
import { fetchWrapper } from "_helpers";
import moment from "moment/moment";
import { useEffect, useState } from "react";
import { Add } from "./Add";

export function Audiences({leadId, onLeadUpdated}) {
    const [loading, setLoading] = useState(true);
    const [audiences, setAudiences] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [deleteDialog, setDeleteDialog] = useState([]);

    useEffect(() => {
        loadAudiences();
    }, [leadId])

    const loadAudiences = () => {
        setLoading(true)
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/vendor/lead/" + leadId + "/audiences").then(response => {
            setAudiences(response.data);
            setTotalElements(response.data.length)
            setLoading(false)
        });
    }

    const deleteAudience = () => {
        fetchWrapper.delete(process.env.REACT_APP_API_URL + "/vendor/lead/" + leadId + "/audience/" + deleteDialog.id).then(response => {
            if (response.status === 200) {
                loadAudiences();
                onLeadUpdated();
            }
            setDeleteDialog({show: false})
        });
    }

    return (
        <>
            <div className="row" style={{backgroundColor: "#e2e2e2"}}>
                <div className="col">
                    <h6 className="mt-1 mb-1"><strong>Audiences{totalElements > 0 && <span className="ms-2">({totalElements})</span>}</strong></h6>
                </div>
                <div className="col-2">
                    <Add leadId={leadId} successCallBack={() => {loadAudiences(); onLeadUpdated();}} />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    {loading === true ? <Loading /> : 
                        audiences.length === 0 ? 
                            <div className="row justify-content-center">
                                <div className="col">
                                    <NoResults />
                                </div>
                            </div>
                        :
                            audiences.map((item) => 
                                <div key={item.id}>
                                    <p className="mb-0">
                                        <AudienceTitle audience={item} />&nbsp;<a className="ms-1 cursor text-danger" onClick={() => setDeleteDialog({show: true, id: item.id})}><FontAwesomeIcon icon={faX}/></a></p>
                                    <p className="text-secondary mb-0 fs-6">From {moment(item.creationDate).format('MMM Do YYYY')}</p>
                                </div>
                            )
                    }
                </div>
            </div>
            <ConfirmationDialog 
                show={deleteDialog.show}
                message="You are about to delete this audience. Are you sure?"
                handleConfirm={deleteAudience} 
                handleCancel={() => setDeleteDialog({show: false, id: null})}
            />
        </>
    )

}