import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';

import { history } from '_helpers';
import { authActions } from '_store';
import { Link } from 'react-router-dom';
import { PasswordInputField } from '_components';

export { Login };

function Login() {
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const authError = useSelector(x => x.auth.error);
    // form validation rules 
    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Email is required').email("Please enter a valid email address"),
        password: Yup.string().required('Password is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, setFocus, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    useEffect(() => {
        // redirect to home if already logged in
        setFocus("username");
        if (authUser) history.navigate('/');
    }, []);


    function onSubmit({ username, password }) {
        return dispatch(authActions.login({ username, password }));
    }

    return (
        <>
        <div className="row g-0 justify-content-center">
            <div className="col-sm-6">
                <div className="d-none d-lg-block d-md-block">
                    <img src="/login-optimized.png" alt="logo" className='img-fluid float-end' />
                </div>
                <div className='d-block d-sm-none' >
                    <img src="/login-narrow.png" alt="logo" className='img-fluid' />
                </div>
            </div>
            <div className="col-sm-5">
                <div className='row g-0'>
                    <div className='col-1 d-none d-lg-block'>&nbsp;</div>
                    <div className='col'>
                        <div className='mt-4 d-none d-lg-block'>&nbsp;</div>
                        <div className='mt-4 d-none d-lg-block'>&nbsp;</div>
                        <h3 className='text-center mt-4'><strong>Sign in</strong></h3>
                        <div className='mt-4 d-none d-lg-block'>&nbsp;</div>
                        <div className='container'>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-group mb-4">
                                    <label className="mb-2">Email</label>
                                    <input name="username" type="text" {...register('username')} maxLength={100} className={`form-control ${errors.username ? 'is-invalid' : ''}`} />
                                    <div className="invalid-feedback">{errors.username?.message}</div>
                                </div>
                                <PasswordInputField register={register} error={errors.password} />
                                <div className="d-grid gap-2">
                                <button disabled={isSubmitting} className="btn btn-dark rounded-pill mt-3">
                                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                                    Sign In
                                </button>
                                </div>
                                {authError &&
                                    <div className="alert alert-danger mt-3 mb-0">Please check credentials and try again.</div>
                                }
                                <div className="mt-3">
                                    <p className='mb-0'><Link to="/password/forget" >Forgot password?</Link></p>
                                    <p><Link to="/register" >New to ABC? Sign Up.</Link></p>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className='col-3 d-none d-lg-block'>&nbsp;</div>
                </div>
            </div>
            <div className="col"></div>
        </div>
        <div className="d-flex flex-wrap justify-content-center align-items-center py-3" style={{backgroundColor: "#F2F3FA"}}>
            <p className="mb-0 text-muted text-center">Copyright Seascapes Technologies Inc. 2022 - 2025. All rights reserved.</p>
            <ul className="nav justify-content-center">
                <li className="nav-item"><a href="https://abccrm.ca/terms" className="nav-link px-2 text-muted">Terms Of Use</a></li>
                <li className="nav-item"><a href="https://abccrm.ca/contact" className="nav-link px-2 text-muted">Contact Us</a></li>
            </ul>
        </div>
        </>
    )
}
