import { useState, useEffect, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import FileResizer from "react-image-file-resizer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";

import { ConfirmationDialog, SubmitBar, TextInputField } from "_components";
import { CountriesSelectField } from "_components/CountriesSelectField";
import { RealEstateRoom } from "./RealEstateRoom";
import { constants, fetchWrapper } from "_helpers";
import CallToAction, { callToActionValidationSchema } from '../CallToAction';
import LimitedImageUpload from "./real-estate/LimitedImageUpload";

// Generate a unique ID for rooms
const generateUniqueId = () => {
    return 'room_' + Date.now() + '_' + Math.random().toString(36).substr(2, 9);
};

// Helper functions moved outside component to prevent recreation on each render
const dataURLtoBlob = (dataurl) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
};

const resizeFile = (file) =>
    new Promise((resolve) => {
        FileResizer.imageFileResizer(
            file,
            2048,
            1080,
            "JPEG",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "blob"
        );
    });

// Room card component
const RoomCard = ({ room, index, onEditClick, onDeleteClick }) => {
    return (
        <div className="row mb-2">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title">
                            <a href="#container-rooms" onClick={() => onEditClick(room.id)}>
                                {room.roomType}
                            </a>
                            <a
                                href="#container-rooms"
                                onClick={() => onDeleteClick(room.id)}
                                className="btn btn-link btn-sm text-danger"
                            >
                                <FontAwesomeIcon icon={faX} />
                            </a>
                        </h5>
                        {room.level && <p className="mb-0">Level: {room.level}</p>}
                        {room.dimension1 && <p className="mb-0">Dimension 1: {room.dimension1}</p>}
                        {room.dimension2 && <p className="mb-0">Dimension 2: {room.dimension2}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
};

// Form sections to reduce component complexity
const PropertyBasicInfo = ({ register, errors, authUser }) => (
    <>
        <TextInputField register={register} label="Title" name="title" error={errors.title} maxLength="100" />
        <TextInputField register={register} label="Description" name="description" error={errors.description} textarea={true} />
        <div className="form-group mb-4">
            <label className="mb-1">Price</label>
            <div className="input-group mb-3">
                <input type="hidden"  {...register('data.currency')} value={authUser.currency} />
                <span className="input-group-text">{authUser.currency}</span>
                <input
                    type='number'
                    {...register("data.price")}
                    maxLength={30}
                    className={`form-control ${errors.data?.price ? 'is-invalid' : ''}`}
                />
                {errors.data?.price && (
                    <div className="invalid-feedback">{errors.data.price.message}</div>
                )}
            </div>
        </div>
    </>
);

const PropertyAddressInfo = ({ register, setValue, selectedCountry }) => (
    <>
        <TextInputField register={register} label="Address 1" name="data.address1" maxLength={100} />
        <TextInputField register={register} label="Address 2" name="data.address2" maxLength={100} />
        <CountriesSelectField
            register={register}
            setValue={setValue}
            name="data.country"
            valueName={true}
            selected={selectedCountry}
        />
        <TextInputField register={register} label="State" name="data.state" maxLength={100} />
        <TextInputField register={register} label="City" name="data.city" maxLength={100} />
        <TextInputField register={register} label="Zip" name="data.postalCode" maxLength={100} />
    </>
);

const PropertyCharacteristics = ({ register }) => (
    <>
        <TextInputField register={register} label="Beds" name="data.beds" maxLength="3" />
        <TextInputField register={register} label="Baths" name="data.baths" maxLength="3" />
        <TextInputField register={register} label="Total Floor Area in sqft" name="data.totalFlArea" maxLength="50" />
        <TextInputField register={register} label="Description of the property" name="data.remarks" textarea={true} />
    </>
);

const PropertyDetails = ({ register }) => (
    <>
        <h4 className="mb-3">Property Information</h4>
        <TextInputField register={register} label="ML#" name="data.ml" maxLength="100" />
        <TextInputField register={register} label="Area" name="data.area" maxLength="10" />
        <TextInputField register={register} label="Floor Area Fin" name="data.floorAreaFin" maxLength="50" />
        <TextInputField register={register} label="Age" name="data.age" maxLength="10" />
        <TextInputField register={register} label="Fireplaces" name="data.fireplaces" maxLength="10" />
        <TextInputField register={register} label="Gross taxes" name="data.grossTaxes" maxLength="100" />
        <TextInputField register={register} label="For Tax Year" name="data.forTaxYear" maxLength="100" />
        <hr className="my-4"></hr>
    </>
);

const PropertyMedia = ({ register, errors }) => (
    <>
        <h4 className="mb-3">Media links</h4>
        <TextInputField
            register={register}
            label="Media link"
            name="data.mediaLink"
            error={errors.data?.mediaLink}
            maxLength="150"
        />
        <hr className="my-4"></hr>
    </>
);

const PropertyFeatures = ({ register }) => (
    <>
        <h4 className="mb-3">Features</h4>
        <TextInputField register={register} label="Style of home" name="data.styleOfHome" maxLength="100" />
        <TextInputField register={register} label="Parking" name="data.parking" maxLength="100" />
        <TextInputField register={register} label="Bylaw Restrictions" name="data.bylawRestrictions" maxLength="100" />
        <TextInputField register={register} label="Construction" name="data.construction" maxLength="100" />
        <TextInputField register={register} label="Amenities" name="data.amenities" maxLength="100" />
        <TextInputField register={register} label="Maint Fee Includes" name="data.maintFeeIncludes" maxLength="100" />
        <TextInputField register={register} label="Fuel/Heating" name="data.heating" maxLength="100" />
        <TextInputField register={register} label="Roof" name="data.roof" maxLength="100" />
        <hr className="my-4"></hr>
    </>
);

export function RealEstate({ offer, selectedTemplateId }) {
    const navigate = useNavigate();
    const authUser = useSelector(x => x.auth.user);

    // State variables grouped by purpose
    const [rooms, setRooms] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);

    // Images state
    const [existingImages, setExistingImages] = useState([]);
    const [images, setImages] = useState([]);
    const [removeImages, setRemoveImages] = useState([]);

    // Dialog states
    const [roomDialog, setRoomDialog] = useState({
        show: false,
        id: null,
        isNewRoom: false
    });

    const [roomDeleteDialog, setRoomDeleteDialog] = useState({
        show: false,
        roomId: null
    });

    const [deletePictureConfirmationDialog, setDeletePictureConfirmationDialog] = useState({
        show: false,
        id: null
    });

    const [deleteExistingImageConfirmationDialog, setDeleteExistingImageConfirmationDialog] = useState({
        show: false,
        id: null,
        info: null
    });

    // Form validation schema
    const validationSchema = Yup.object({
        title: Yup.string().required('Title is required field'),
        data: Yup.object({
            mediaLink: Yup.string().matches(
                constants.websiteRegExp,
                { message: "Media link must be a valid URL", excludeEmptyString: true }
            ),
        }),
        // Spread the Call To Action validation schema fields here
        ...callToActionValidationSchema.fields
    });

    // Form setup
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };

    const {
        register,
        handleSubmit,
        formState,
        setValue,
        getValues
    } = useForm(formOptions);

    const { errors, isSubmitting } = formState;

    // Load offer data when component mounts or offer changes
    useEffect(() => {
        if (offer !== undefined) {
            // Set basic form values
            setValue('title', offer.title);
            setValue('description', offer.description);

            try {
                const content = JSON.parse(offer.content);

                // Set all form data from content
                const formFields = [
                    'price', 'currency', 'address1', 'address2', 'state',
                    'city', 'postalCode', 'beds', 'baths', 'totalFlArea',
                    'remarks', 'ml', 'area', 'floorAreaFin', 'age',
                    'fireplaces', 'grossTaxes', 'forTaxYear', 'mediaLink',
                    'styleOfHome', 'parking', 'bylawRestrictions', 'construction',
                    'amenities', 'maintFeeIncludes', 'heating', 'roof',
                    'listingProvidedCourtesyOf'
                ];

                formFields.forEach(field => {
                    if (content[field] !== undefined) {
                        setValue(`data.${field}`, content[field]);
                    }
                });

                // Set rooms if exists - ensure all rooms have unique IDs
                if (content.rooms && Array.isArray(content.rooms)) {
                    const roomsWithIds = content.rooms.map(room => ({
                        ...room,
                        id: room.id || generateUniqueId()
                    }));
                    setRooms(roomsWithIds);
                }

                // Set country if exists
                if (content.country) {
                    setSelectedCountry(content.country);
                }

                // Load images
                loadPictures();
            } catch (error) {
                console.error("Error parsing offer content:", error);
            }
        }
    }, [offer, setValue]);

    // Load pictures from the server
    const loadPictures = useCallback(() => {
        if (!offer || !offer.id) return;

        fetchWrapper.get(`${process.env.REACT_APP_API_URL}/vendor/offer/${offer.id}/pictures`)
            .then(response => {
                setExistingImages(response.data);
            })
            .catch(error => {
                console.error("Error loading pictures:", error);
            });
    }, [offer]);

    const onSelectFile = useCallback(e => {
        if (e.target.files && e.target.files.length > 0) {
            // Check if adding this image would exceed the limit
            const maxImages = 16;
            if (existingImages.length + images.length >= maxImages) {
                // You could also show a toast/alert here to inform the user
                console.warn(`Maximum image limit (${maxImages}) reached`);
                return;
            }

            const reader = new FileReader();
            reader.addEventListener('load', () => {
                setImages(prevImages => [...prevImages, reader.result]);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }, [existingImages.length, images.length]);

    // Handle image deletion
    const handleImageDelete = useCallback((isExisting, index, id, info) => {
        if (isExisting) {
            setDeleteExistingImageConfirmationDialog({ show: true, id, info });
        } else {
            setDeletePictureConfirmationDialog({ show: true, id: index });
        }
    }, []);

    // Delete a newly added image
    const deletePicture = useCallback(() => {
        setImages(prevImages => {
            const tempImages = [...prevImages];
            tempImages.splice(deletePictureConfirmationDialog.id, 1);
            return tempImages;
        });
        setDeletePictureConfirmationDialog({ show: false });
    }, [deletePictureConfirmationDialog.id]);

    // Delete an existing image
    const deleteExistingImage = useCallback(() => {
        setExistingImages(prevImages =>
            prevImages.filter(element => element.id !== deleteExistingImageConfirmationDialog.id)
        );

        if (deleteExistingImageConfirmationDialog.info !== undefined) {
            setRemoveImages(prevRemoveImages => {
                const tempRemoveImages = [...prevRemoveImages];
                tempRemoveImages[prevRemoveImages.length] = deleteExistingImageConfirmationDialog.info;
                return tempRemoveImages;
            });
        }

        setDeleteExistingImageConfirmationDialog({ show: false });
    }, [deleteExistingImageConfirmationDialog]);

    // Handle room actions - IMPROVED IMPLEMENTATION
    const handleRoomAdded = useCallback((room) => {
        // Check if room exists by ID
        const existingRoomIndex = rooms.findIndex(item => item.id === room.id);

        if (existingRoomIndex === -1) {
            // Add new room with a unique ID
            const newRoom = {
                ...room,
                id: generateUniqueId() // Generate a proper unique ID
            };

            const newRooms = [...rooms, newRoom];
            setRooms(newRooms);
            setValue('data.rooms', newRooms);
        } else {
            // Update existing room
            setRooms(prevRooms => {
                const updatedRooms = [...prevRooms];
                updatedRooms[existingRoomIndex] = {
                    ...room,
                    id: prevRooms[existingRoomIndex].id // Preserve the original ID
                };
                setValue('data.rooms', updatedRooms);
                return updatedRooms;
            });
        }

        setRoomDialog({ show: false, id: null, isNewRoom: false });
    }, [rooms, setValue]);

    // Handle room deletion
    const deleteRoom = useCallback(() => {
        if (roomDeleteDialog.roomId === null) return;

        setRooms(prevRooms => {
            // Find room by ID instead of index for more reliable deletion
            const updatedRooms = prevRooms.filter(room => room.id !== roomDeleteDialog.roomId);
            setValue('data.rooms', updatedRooms);
            return updatedRooms;
        });

        setRoomDeleteDialog({ show: false, roomId: null });
    }, [roomDeleteDialog.roomId, setValue]);

    // Handle room dialog opening for edit
    const handleRoomEdit = useCallback((roomId) => {
        setRoomDialog({
            show: true,
            id: roomId,
            isNewRoom: false
        });
    }, []);

    // Handle room dialog opening for adding new room
    const handleAddRoom = useCallback(() => {
        setRoomDialog({
            show: true,
            id: null,
            isNewRoom: true
        });
    }, []);

    // Handle form submission
    const onSubmit = useCallback(async () => {
        const formData = new FormData();

        // Process and add new images
        try {
            for (const file of images) {
                const contents = await resizeFile(dataURLtoBlob(file));
                formData.append("files", contents);
            }

            // Prepare form data
            if (offer) {
                // Update existing offer
                formData.append("removeImages", removeImages);
                formData.append("offer", JSON.stringify({
                    title: getValues('title'),
                    description: getValues('description'),
                    data: getValues('data')
                }));

                const response = await fetchWrapper.postFormData(
                    `${process.env.REACT_APP_API_URL}/vendor/offer/${offer.id}`,
                    formData
                );

                if (response.status === 200) {
                    navigate("/offers");
                }
            } else {
                // Create new offer
                formData.append("offer", JSON.stringify({
                    title: getValues('title'),
                    description: getValues('description'),
                    templateId: selectedTemplateId,
                    data: getValues('data')
                }));

                const response = await fetchWrapper.postFormData(
                    `${process.env.REACT_APP_API_URL}/vendor/offer`,
                    formData
                );

                if (response.status === 200) {
                    navigate("/offers");
                }
            }
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    }, [images, removeImages, offer, getValues, navigate, selectedTemplateId]);

    // Find the currently selected room for the room dialog
    const selectedRoom = useMemo(() => {
        if (!roomDialog.id) return null;
        return rooms.find(element => element.id === roomDialog.id);
    }, [rooms, roomDialog.id]);

    return (
        <div className="row justify-content-center">
            <div className="col-lg-5">
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* Basic Information */}
                    <PropertyBasicInfo
                        register={register}
                        errors={errors}
                        authUser={authUser}
                    />

                    {/* Address Information */}
                    <PropertyAddressInfo
                        register={register}
                        setValue={setValue}
                        selectedCountry={selectedCountry}
                    />

                    {/* Property Characteristics */}
                    <PropertyCharacteristics register={register} />

                    {/* Property Details */}
                    <PropertyDetails register={register} />

                    {/* Media Links */}
                    <PropertyMedia register={register} errors={errors} />

                    {/* Property Features */}
                    <PropertyFeatures register={register} />

                    {/* Rooms Section */}
                    
                        <h4 id="container-rooms">Rooms</h4>
                        <button
                            onClick={handleAddRoom}
                            className="btn btn-link"
                            type="button"
                        >
                            Add New Room
                        </button>

                    {rooms.map((room) => (
                            <RoomCard
                                key={room.id}
                                room={room}
                                index={0} // Index no longer needed for delete
                                onEditClick={handleRoomEdit}
                                onDeleteClick={(roomId) => setRoomDeleteDialog({ show: true, roomId })}
                            />
                        ))
                    }

                    <hr className="my-4"></hr>
                    <TextInputField
                        register={register}
                        label="Listing provided courtesy of"
                        name="data.listingProvidedCourtesyOf"
                        maxLength="100"
                    />

                    <LimitedImageUpload
                        existingImages={existingImages}
                        newImages={images}
                        onSelectFile={onSelectFile}
                        onDeleteImage={handleImageDelete}
                        maxImages={16}
                        imagesPerRow={4}
                        imageHeight={120}
                        className="mb-4"
                    />

                    {/* Call To Action Component */}
                    <CallToAction register={register} errors={errors} />

                    {/* Submit Bar */}
                    <SubmitBar
                        isSubmitting={isSubmitting}
                        labelConfirm="Save"
                        cancelLink="/offers"
                    />
                </form>

                {/* Confirmation Dialogs */}
                <ConfirmationDialog
                    show={roomDeleteDialog.show}
                    message="You are about to delete this room. Are you sure?"
                    handleConfirm={deleteRoom}
                    handleCancel={() => setRoomDeleteDialog({ show: false, roomId: null })}
                />

                <ConfirmationDialog
                    show={deletePictureConfirmationDialog.show}
                    message="To remove the image from the offer, after Confirming deletion, click the Save button at the end of your work."
                    handleConfirm={deletePicture}
                    handleCancel={() => setDeletePictureConfirmationDialog({ show: false })}
                />

                <ConfirmationDialog
                    show={deleteExistingImageConfirmationDialog.show}
                    message="To remove the image from the offer, after Confirming deletion, click the Save button at the end of your work."
                    handleConfirm={deleteExistingImage}
                    handleCancel={() => setDeleteExistingImageConfirmationDialog({ show: false })}
                />

                {/* Room Modal */}
                <Modal
                    show={roomDialog.show}
                    onHide={() => setRoomDialog({ show: false, id: null, isNewRoom: false })}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{roomDialog.isNewRoom ? 'Add New Room' : 'Edit Room'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <RealEstateRoom
                            handleConfirmed={handleRoomAdded}
                            id={roomDialog.id}
                            room={selectedRoom}
                        />
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
}