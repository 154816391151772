import { constants, fetchWrapper } from "_helpers";
import { useEffect, useState, useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ConfirmationDialog, SubmitBar, TextAreaField, TextInputField } from "_components";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import FileResizer from "react-image-file-resizer";

// Custom components to reduce clutter in main component
const ImageUploader = ({ image, imageSrc, onSelectFile, onDeleteClick, setImageSrc }) => {
    if (image !== null) {
        return (
            <div className="img-container" style={{ flex: "1", position: "relative", margin: "0.5rem" }}>
                <img 
                    src={`/uploads/offer/${image.path}`} 
                    style={{ objectFit: "cover", height: "23rem", width: "100%" }} 
                    alt={image.path}
                />
                <span className="position-absolute top-0 start-100 translate-middle rounded-pill bg-light">
                    <button 
                        type="button" 
                        className="btn btn-link btn-sm text-danger" 
                        onClick={onDeleteClick}
                    >
                        <FontAwesomeIcon icon={faX} />
                    </button>
                </span>
            </div>
        );
    }

    if (imageSrc === null) {
        return (
            <div className="img-container" style={{ flex: "1", position: "relative", margin: "0.5rem", height: "23rem", borderRadius: ".475rem", border: ".01rem solid" }}>
                <div className="form-group mb-2 position-absolute top-50 start-50 translate-middle rounded-pill">
                    <input 
                        className="form-control" 
                        type="file" 
                        onChange={(e) => onSelectFile(e, setImageSrc)} 
                        accept="image/*" 
                        max={1} 
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="img-container" style={{ flex: "1", position: "relative", margin: "0.5rem" }}>
            <img 
                src={imageSrc} 
                style={{ objectFit: "cover", height: "23rem", width: "100%" }} 
                alt="Selected preview" 
            />
            <span className="position-absolute top-0 start-100 translate-middle rounded-pill bg-light">
                <button 
                    type="button" 
                    className="btn btn-link btn-sm text-danger" 
                    onClick={onDeleteClick}
                >
                    <FontAwesomeIcon icon={faX} />
                </button>
            </span>
        </div>
    );
};

// Helper functions moved outside component
const resizeFile = (file) =>
    new Promise((resolve) => {
        FileResizer.imageFileResizer(
            file,
            2048,
            1080,
            "png",
            100,
            0,
            (uri) => {
                resolve(uri);
            },
            "blob"
        );
    });

const dataURLtoBlob = (dataurl) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
};

export function ScanAndSubscribe({ offer, selectedTemplateId }) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    
    // Group related state together
    const [pages, setPages] = useState([]);
    
    // User data
    const [profile, setProfile] = useState(null);
    const [company, setCompany] = useState(null);
    
    // Phone numbers
    const [profilePhoneNumbers, setProfilePhoneNumbers] = useState([]);
    const [companyPhoneNumbers, setCompanyPhoneNumbers] = useState([]);
    const [phoneNumbers, setPhoneNumbers] = useState([]);
    
    // Selection state
    const [selectedLandingPage, setSelectedLandingPage] = useState(null);
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);
    
    // Main image state
    const [mainImageSrc, setMainImageSrc] = useState(null);
    const [mainImageResizedSrc, setMainImageResizedSrc] = useState(null);
    const [mainImage, setMainImage] = useState(null);
    
    // Left image state
    const [leftImageSrc, setLeftImageSrc] = useState(null);
    const [leftImageResizedSrc, setLeftImageResizedSrc] = useState(null);
    const [leftImage, setLeftImage] = useState(null);
    
    // Right image state
    const [rightImageSrc, setRightImageSrc] = useState(null);
    const [rightImageResizedSrc, setRightImageResizedSrc] = useState(null);
    const [rightImage, setRightImage] = useState(null);
    
    const [removeImages, setRemoveImages] = useState([]);
    
    // Confirmation dialog states
    const [deletePictureConfirmationDialog, setDeletePictureConfirmationDialog] = useState({
        show: false
    });
    const [deleteLeftPictureConfirmationDialog, setDeleteLeftPictureConfirmationDialog] = useState({
        show: false
    });
    const [deleteRightPictureConfirmationDialog, setDeleteRightPictureConfirmationDialog] = useState({
        show: false
    });

    // Form validation
    const validationSchema = Yup.object({
        title: Yup.string().required('Title is required field'),
        data: Yup.object({
            welcomeMessage: Yup.string().required('Welcome message is a required field'),
            companyWebsite: Yup.string().nullable().matches(
                constants.websiteRegExp, 
                { message: "Calendar URL be valid!", excludeEmptyString: true }
            ),
            landingPageId: Yup.string().required('Landing page is a required field')
        })
    });
    
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true
    };
    
    const {
        register,
        handleSubmit,
        formState,
        setValue,
        getValues
    } = useForm(formOptions);
    
    const { errors, isSubmitting } = formState;

    // Load offer data
    useEffect(() => {
        if (offer) {
            loadPictures();
            setValue('title', offer.title);
            setValue('description', offer.description);
            
            const content = JSON.parse(offer.content);
            setValue('data.companyWebsite', content.companyWebsite);
            setValue('data.welcomeMessage', content.welcomeMessage);
            setValue('data.info', content.info);
            setValue('data.landingPageId', content.landingPageId);
            setValue('data.phoneNumber', content.phoneNumber);
            
            setSelectedLandingPage(content.landingPageId);
            setSelectedPhoneNumber(content.phoneNumber);
        } else {
            setValue('data.welcomeMessage', "Welcome");
        }
        
        // Load required data
        loadPages();
        loadProfile();
        loadCompany();
    }, [offer, setValue]);

    // Process images with memoized function
    const processImage = useCallback(async (imageSrc, setImageResizedSrc) => {
        if (imageSrc) {
            try {
                const blob = dataURLtoBlob(imageSrc);
                const resizedImage = await resizeFile(blob);
                setImageResizedSrc(resizedImage);
            } catch (error) {
                console.error('Error processing image:', error);
            }
        }
    }, []);

    // Image processing effects using the same callback
    useEffect(() => {
        if (mainImageSrc) processImage(mainImageSrc, setMainImageResizedSrc);
    }, [mainImageSrc, processImage]);

    useEffect(() => {
        if (leftImageSrc) processImage(leftImageSrc, setLeftImageResizedSrc);
    }, [leftImageSrc, processImage]);

    useEffect(() => {
        if (rightImageSrc) processImage(rightImageSrc, setRightImageResizedSrc);
    }, [rightImageSrc, processImage]);

    // API calls memoized as callbacks to avoid recreating on renders
    const loadPictures = useCallback(() => {
        if (!offer || !offer.id) return;
        
        fetchWrapper.get(`${process.env.REACT_APP_API_URL}/vendor/offer/${offer.id}/pictures`).then(response => {
            const pictures = response.data;
            pictures.forEach(picture => {
                if (picture.info === 'mainImage') {
                    setMainImage(picture);
                } else if (picture.info === 'leftImage') {
                    setLeftImage(picture);
                } else if (picture.info === 'rightImage') {
                    setRightImage(picture);
                }
            });
        }).catch(err => console.error("Error loading pictures:", err));
    }, [offer]);

    const loadPages = useCallback(() => {
        fetchWrapper.get(`${process.env.REACT_APP_API_URL}/vendor/pages`).then(response => {
            const data = response.data;
            setPages(data);
            
            if (selectedLandingPage === null) {
                const selectedPageId = data.filter(item => item.destination === "PERSONAL")
                    .map(item => item.id)[0];
                
                if (selectedPageId) {
                    setValue('data.landingPageId', selectedPageId);
                    setSelectedLandingPage(selectedPageId);
                }
            }
        }).catch(err => console.error("Error loading pages:", err));
    }, [selectedLandingPage, setValue]);

    const loadProfile = useCallback(() => {
        fetchWrapper.get(`${process.env.REACT_APP_API_URL}/vendor/my/personal`).then(response => {
            setProfile(response.data);
            if (response.data.phoneNumbers) {
                setProfilePhoneNumbers(response.data.phoneNumbers);
            }
        }).catch(err => console.error("Error loading profile:", err));
    }, []);

    const loadCompany = useCallback(() => {
        fetchWrapper.get(`${process.env.REACT_APP_API_URL}/vendor/my/company`).then(response => {
            setCompany(response.data);
            
            if (response.data.phoneNumbers) {
                setCompanyPhoneNumbers(response.data.phoneNumbers);
            }
            
            if (offer === undefined) {
                setValue("data.companyWebsite", response.data.website || "");
                
                if (response.data.name) {
                    setValue("data.welcomeMessage", `Welcome to ${response.data.name}`);
                }
                
                if (response.data.addresses && response.data.addresses.length > 0) {
                    setValue("data.info", response.data.addresses[0].displayText);
                }
            }
        }).catch(err => console.error("Error loading company:", err));
    }, [offer, setValue]);

    // Combine phone numbers
    useEffect(() => {
        const numberSet = new Set([
            ...profilePhoneNumbers.map(ph => ph.value),
            ...companyPhoneNumbers.map(ph => ph.value)
        ]);
        setPhoneNumbers(Array.from(numberSet));
    }, [profilePhoneNumbers, companyPhoneNumbers]);

    // Handler functions
    const onLandingPageChanged = useCallback((event) => {
        setSelectedLandingPage(event.target.value);
    }, []);

    const onSelectFile = useCallback((e, srcFunction) => {
        srcFunction(null);
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                srcFunction(reader.result);
            });
            reader.readAsDataURL(e.target.files[0]);
        }
    }, []);

    const deleteImage = useCallback((setImageSrc, setImage, index, info, setDeleteConfirmDialog) => {
        setImageSrc(null);
        setImage(null);
        
        if (index !== undefined) {
            setRemoveImages(prev => {
                const temp = [...prev];
                temp[index] = info;
                return temp;
            });
        }
        
        setDeleteConfirmDialog({ show: false });
    }, []);

    // Submit handler - reverted to match original implementation to fix fetch error
    const onSubmit = async () => {
        const formData = new FormData();
        
        // Helper to add images to FormData
        const addImageToFormData = (imageSrc, fileName) => {
            if (imageSrc) {
                formData.append("files", imageSrc, fileName);
            }
        };
        
        // Add all images
        addImageToFormData(mainImageResizedSrc, "mainImage");
        addImageToFormData(leftImageResizedSrc, "leftImage");
        addImageToFormData(rightImageResizedSrc, "rightImage");
        
        if (offer) {
            formData.append("offer", JSON.stringify({ 
                "title": getValues('title'), 
                "description": getValues('description'), 
                "data": getValues('data') 
            }));
            formData.append("removeImages", removeImages);
            
            try {
                const response = await fetchWrapper.postFormData(
                    process.env.REACT_APP_API_URL + "/vendor/offer/" + offer.id, 
                    formData
                );
                if (response.status === 200) {
                    navigate("/offers");
                }
            } catch (error) {
                console.error("Error submitting form:", error);
            }
        } else {
            formData.append("offer", JSON.stringify({ 
                "title": getValues('title'), 
                "description": getValues('description'), 
                "templateId": selectedTemplateId, 
                "data": getValues('data') 
            }));
            
            try {
                const response = await fetchWrapper.postFormData(
                    process.env.REACT_APP_API_URL + "/vendor/offer", 
                    formData
                );
                if (response.status === 200) {
                    navigate("/offers");
                }
            } catch (error) {
                console.error("Error submitting form:", error);
            }
        }
    };

    // Memoize confirmation dialog configurations
    const mainImageDeleteConfig = useMemo(() => ({
        show: deletePictureConfirmationDialog.show,
        message: "To remove the image from the offer, after Confirming deletion, click the Save button at the end of your work.",
        handleConfirm: () => deleteImage(
            setMainImageSrc,
            setMainImage,
            deletePictureConfirmationDialog.id,
            deletePictureConfirmationDialog.info,
            setDeletePictureConfirmationDialog
        ),
        handleCancel: () => setDeletePictureConfirmationDialog({ show: false })
    }), [deletePictureConfirmationDialog, deleteImage]);

    const leftImageDeleteConfig = useMemo(() => ({
        show: deleteLeftPictureConfirmationDialog.show,
        message: "To remove the image from the offer, after Confirming deletion, click the Save button at the end of your work.",
        handleConfirm: () => deleteImage(
            setLeftImageSrc,
            setLeftImage,
            deleteLeftPictureConfirmationDialog.id,
            deleteLeftPictureConfirmationDialog.info,
            setDeleteLeftPictureConfirmationDialog
        ),
        handleCancel: () => setDeleteLeftPictureConfirmationDialog({ show: false })
    }), [deleteLeftPictureConfirmationDialog, deleteImage]);

    const rightImageDeleteConfig = useMemo(() => ({
        show: deleteRightPictureConfirmationDialog.show,
        message: "To remove the image from the offer, after Confirming deletion, click the Save button at the end of your work.",
        handleConfirm: () => deleteImage(
            setRightImageSrc,
            setRightImage,
            deleteRightPictureConfirmationDialog.id,
            deleteRightPictureConfirmationDialog.info,
            setDeleteRightPictureConfirmationDialog
        ),
        handleCancel: () => setDeleteRightPictureConfirmationDialog({ show: false })
    }), [deleteRightPictureConfirmationDialog, deleteImage]);

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="container scan-and-subcribe">
                    {/* Title Section */}
                    <div className="row justify-content-center">
                        <div className="col-lg-5">
                            <TextInputField 
                                register={register} 
                                label="Title" 
                                name="title" 
                                error={errors.title} 
                                maxLength="100" 
                            />
                            <TextInputField 
                                register={register} 
                                label="Subtitle" 
                                name="description" 
                                error={errors.description} 
                                textarea={true} 
                            />
                            <p className="mb-0 fs-6">These fields will not appear in the print view—they are for informational purposes only.</p>
                        </div>
                    </div>
                    
                    <div className="mb-3" style={{ borderBottom: ".07rem dotted" }}>&nbsp;</div>
                    
                    {/* Contact Section */}
                    <div className="row mt-3 mb-3">
                        <div className="col-lg-3 col-md-6 col-sm-12">
                            <TextInputField 
                                register={register} 
                                placeholder="Company website" 
                                name="data.companyWebsite" 
                                maxLength="100" 
                                error={errors.data?.companyWebsite} 
                            />
                            <div className="form-group mb-4">
                                <select 
                                    id="phoneNumber" 
                                    {...register('data.phoneNumber')} 
                                    className="form-select"
                                >
                                    <option value="">Choose Phone number</option>
                                    {phoneNumbers.map(phoneNumber => (
                                        <option 
                                            key={phoneNumber} 
                                            value={phoneNumber} 
                                            selected={phoneNumber === selectedPhoneNumber}
                                        >
                                            {phoneNumber}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    
                    {/* Welcome Message */}
                    <div className="row mt-3 mb-2 justify-content-center">
                        <div className="col-lg-5 col-md-8 col-sm-12 text-center">
                            <TextInputField 
                                register={register} 
                                placeholder="Welcome message" 
                                name="data.welcomeMessage" 
                                error={errors.data?.welcomeMessage} 
                                maxLength="100" 
                            />
                        </div>
                    </div>
                    
                    {/* Main Image */}
                    <div className="row justify-content-center">
                        <div className="col text-center">
                            <ImageUploader
                                image={mainImage}
                                imageSrc={mainImageSrc}
                                onSelectFile={onSelectFile}
                                setImageSrc={setMainImageSrc}
                                onDeleteClick={() => setDeletePictureConfirmationDialog({ 
                                    show: true,
                                    id: mainImage ? 0 : undefined,
                                    info: "mainImage"
                                })}
                            />
                        </div>
                    </div>
                    
                    {/* Middle Section: Address, Page, Profile */}
                    <div className="row mt-4 mb-4 justify-content-center">
                        {/* Address Section */}
                        <div className="col-lg-4 col-md-12 mb-3" style={{ borderRight: ".15rem solid" }}>
                            <TextAreaField
                                register={register}
                                setValue={setValue}
                                placeholder="Address"
                                name="data.info"
                                error={errors.data?.info}
                                maxLines={8}
                                rows={8} 
                            />
                        </div>
                        
                        {/* Landing Page Section */}
                        <div className="col-lg-4 col-md-12 mb-3" style={{ borderRight: ".15rem solid" }}>
                            <h4 className="mt-4 text-center montserrat-text">PLEASE SCAN & SUBSCRIBE</h4>
                            <div className="row justify-content-center">
                                <div className="col-9">
                                    <div className="form-group mb-4">
                                        <label htmlFor="pageType" className="mb-1">Landing page</label>
                                        <select 
                                            id="pageType" 
                                            {...register('data.landingPageId', { onChange: onLandingPageChanged })} 
                                            className={`form-select ${errors.data?.landingPageId ? 'is-invalid' : ''}`}
                                        >
                                            {pages.map(landingPage => (
                                                <option 
                                                    key={landingPage.id} 
                                                    value={landingPage.id} 
                                                    selected={landingPage.id == selectedLandingPage}
                                                >
                                                    {landingPage.title !== null 
                                                        ? landingPage.title 
                                                        : t("LANDINGPAGETYPE." + landingPage.destination)
                                                    }
                                                </option>
                                            ))}
                                        </select>
                                        {errors.data?.landingPageId && (
                                            <div className="invalid-feedback">
                                                {errors.data.landingPageId.message}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        {/* Profile Section */}
                        <div className="col-lg-4 col-md-12">
                            {profile && (
                                <>
                                    <h4 className="mt-1">&nbsp;</h4>
                                    <div className="row business-card-body-content">
                                        <div className="col">
                                            {profile.profileImage && (
                                                <div className="text-center">
                                                    <img 
                                                        src={`/uploads/user/${profile.profileImage}`} 
                                                        alt={profile.profileImage} 
                                                        style={{ maxHeight: "8.5rem", borderRadius: ".475rem" }} 
                                                    />
                                                </div>
                                            )}
                                            <h3 className="mt-2 mb-0 business-card-lastname text-center montserrat-text">
                                                {profile.firstName} {profile.lastName}
                                            </h3>
                                            <h5 className="mb-0 text-center montserrat-text">
                                                {profile.occupation}
                                            </h5>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    
                    {/* Bottom Images Row */}
                    <div className="row justify-content-center">
                        {/* Left Image */}
                        <div className="col-lg-6 col-md-12 text-center mb-3">
                            <ImageUploader
                                image={leftImage}
                                imageSrc={leftImageSrc}
                                onSelectFile={onSelectFile}
                                setImageSrc={setLeftImageSrc}
                                onDeleteClick={() => setDeleteLeftPictureConfirmationDialog({ 
                                    show: true,
                                    id: leftImage ? 1 : undefined,
                                    info: "leftImage"
                                })}
                            />
                        </div>
                        
                        {/* Right Image */}
                        <div className="col-lg-6 col-md-12 text-center">
                            <ImageUploader
                                image={rightImage}
                                imageSrc={rightImageSrc}
                                onSelectFile={onSelectFile}
                                setImageSrc={setRightImageSrc}
                                onDeleteClick={() => setDeleteRightPictureConfirmationDialog({ 
                                    show: true,
                                    id: rightImage ? 2 : undefined,
                                    info: "rightImage"
                                })}
                            />
                        </div>
                    </div>
                </div>
                
                {/* Submit Bar */}
                <div className="row justify-content-center">
                    <div className="col-lg-5">
                        <SubmitBar 
                            isSubmitting={isSubmitting} 
                            labelConfirm="Save" 
                            cancelLink="/offers" 
                        />
                    </div>
                </div>
            </form>
            
            {/* Confirmation Dialogs */}
            <ConfirmationDialog {...mainImageDeleteConfig} />
            <ConfirmationDialog {...leftImageDeleteConfig} />
            <ConfirmationDialog {...rightImageDeleteConfig} />
        </>
    );
}