import { fetchWrapper } from "_helpers";
import { SubscribeForm } from "lead/SubscribeForm";
import { useCallback } from "react";

export function LeadForm({ audienceId, callback }) {

    const handleSubmit = useCallback(async (formData, { setError }) => {
        try {
            const url = `${process.env.REACT_APP_API_URL}/vendor/audience/${audienceId}/lead`;
            const response = await fetchWrapper.post(url, formData);

            switch (response.status) {
                case 201:
                    callback(response.data);
                    break;
                case 536:
                    setError("phoneNumber", {
                        type: 'custom',
                        message: "Phone number is not valid! Please try again"
                    }, { shouldFocus: true });
                    break;
                case 541:
                    setError("phoneNumber", {
                        type: 'custom',
                        message: "Lead has been already registered!"
                    }, { shouldFocus: true });
                    break;
                default:
                    setError("phoneNumber", {
                        type: 'custom',
                        message: "Something went wrong. Please try again."
                    }, { shouldFocus: true });
                    break;
            }
        } catch (error) {
            setError("phoneNumber", {
                type: 'custom',
                message: "Something went wrong. Please try again."
            }, { shouldFocus: true });
        }
    }, [audienceId]);

    return (
        <>
            <div className="row justify-content-center">
                <div className="col">
                    <SubscribeForm onSubmit={handleSubmit} showTermsAcceptance={false} showInterestsField={false} />
                </div>
            </div>
        </>
    )

}