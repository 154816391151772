import { faCalendarAlt, faMapPin } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Loading } from "_components";
import moment from "moment";
import { Footer } from "page/Footer";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import normalizeUrl from 'normalize-url';
import { constants } from "_helpers";
import CallToActionButton from "./CallToActionButton";


export function Event({ offer, invitationCode, channelName, preview, children }) {
    const [loading, setLoading] = useState(true);
    const [content, setContent] = useState(null);

    useEffect(() => {
        if (offer !== undefined) {
            let content = JSON.parse(offer.content);
            setContent(content);
        }
        setLoading(false);
    }, [])

    return (
        <>
            {loading === true ? <Loading /> :
                <>
                    <div className="row g-0 justify-content-center ">
                        <div className="col col-lg-6">
                            <div className="row g-0 mt-4">
                                <div className="col">
                                    <h3 className="mb-2 text-center"><strong>{offer.title}</strong></h3>
                                    {offer.description &&
                                        <p className="text-center ms-3 me-3" style={{ whiteSpace: "pre-line" }}>
                                            {offer.description}
                                        </p>
                                    }
                                </div>
                            </div>
                            {offer.pictures[0] &&
                                <div className="row g-0" >
                                    <div className="col text-center">
                                        <img src={"/uploads/offer/" + offer.pictures[0]} className="" style={{ width: "100%", height: "auto", display: "block" }}
                                            alt="preview"
                                        />
                                    </div>
                                </div>
                            }
                            <div className="row g-0 justify-content-center mb-4 mt-4">
                                <div className="col-9 col-md-5 col-lg-5">
                                    {content.fromDate &&
                                        <div className="row g-0">
                                            <div className="col-1">
                                                <FontAwesomeIcon icon={faCalendarAlt} style={{ marginLeft: "0.4rem" }} />
                                            </div>
                                            <div className="col">
                                                <strong>
                                                    {moment(new Date(content.fromDate)).format('dddd, MMM Do, YYYY') != moment(new Date(content.toDate)).format('dddd, MMM Do, YYYY')
                                                        ?
                                                        <>
                                                            <p className="mb-0">{moment(new Date(content.fromDate)).format('dddd, MMM Do, YYYY')} {moment(new Date(content.fromDate)).format('HH:mm')}</p>
                                                            <p className="mb-0">{moment(new Date(content.toDate)).format('dddd, MMM Do, YYYY')} {moment(new Date(content.toDate)).format('HH:mm')}</p>
                                                        </>
                                                        :
                                                        <>
                                                            <p className="mb-0">{moment(new Date(content.fromDate)).format('dddd, MMM Do, YYYY')} {moment(new Date(content.fromDate)).format('HH:mm')} - {moment(new Date(content.toDate)).format('HH:mm')}</p>
                                                        </>
                                                    }
                                                </strong>
                                            </div>
                                        </div>
                                    }
                                    <div className="row g-0">
                                        <div className="col-1">
                                            <FontAwesomeIcon icon={faMapPin} style={{ marginLeft: "0.4rem" }} />
                                        </div>
                                        <div className="col">
                                            <strong>{content.locationPlace}</strong>
                                            <p className="mb-0">
                                                {content.address1}
                                            </p>
                                            <p className="mb-0">
                                                {content.postalCode} {content.city} {content.state}
                                            </p>
                                        </div>
                                    </div>
                                    {content.description &&
                                        <p className="mt-3" style={{ whiteSpace: "pre-line" }}>
                                            {content.description}
                                        </p>
                                    }
                                    <CallToActionButton
                                        url={offer.callToActionURL}
                                        text={offer.callToActionText}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row justify-content-center mt-2 mb-1 g-0" style={{ position: "relative" }}>
                            <div className="col col-9 col-md-5 col-lg-3">
                                {preview === true ?
                                    <div className="text-center mt-4 mb-4">
                                        <QRCode
                                            id={offer.id}
                                            size={128}
                                            style={{ height: "auto", maxWidth: "40%", width: "40%" }}
                                            value={process.env.REACT_APP_BASE_URL + "/" + channelName + "/" + invitationCode}
                                            viewBox={`0 0 256 256`}
                                        />
                                    </div>
                                    :
                                    <div className="mb-4">
                                        {children}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <Footer />
                </>
            }
        </>
    )

}