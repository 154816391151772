import { ConfirmationDialog, Loading, NoResults, Paginate } from "_components";
import { fetchWrapper } from "_helpers";
import { useEffect, useState } from "react"
import { Accordion, Modal } from "react-bootstrap";
import { Edit } from "./Edit";
import moment from "moment";
import { Icon } from "./Icon";
import { useTranslation } from "react-i18next";

export function Notes({ leadId }) {
    const { t } = useTranslation();
    const [notes, setNotes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [editDialog, setEditDialog] = useState({
        show: false,
        id: null
    });
    const [deleteDialog, setDeleteDialog] = useState({
        show: false,
        id: null
    });

    useEffect(() => {
        loadNotes();
    }, [leadId, page]);

    const loadNotes = () => {
        fetchWrapper.post(process.env.REACT_APP_API_URL + "/vendor/lead/" + leadId + "/notes", { "pageNumber": page, "pageSize": 5 }).then(response => {
            if (response.status === 200) {
                setTotalPages(response.data.totalPages);
                setTotalElements(response.data.totalElements)
                setNotes(response.data.content);
                setLoading(false);
            }
        });
    }

    const handlePageClick = (event) => {
        setPage(event.selected);
    };

    const deleteNote = () => {
        fetchWrapper.delete(process.env.REACT_APP_API_URL + "/vendor/lead/" + leadId + "/note/" + deleteDialog.id).then(response => {
            if (response.status === 200) {
                loadNotes();
            }
            setDeleteDialog({ show: false })
        });
    }

    return (
        <>
            <div className="row mt-2" style={{ backgroundColor: "#e2e2e2" }}>
                <div className="col-10">
                    <h6 className="mt-1 mb-1"><strong>Notes{totalElements > 0 && <span className="ms-2">({totalElements})</span>}</strong></h6>
                </div>
                <div className="col-2">
                    <a onClick={() => { setEditDialog({ show: true, id: null }) }} className="float-end cursor"><img src="circle-plus-regular.svg" width={20} alt="tag-add" /></a>
                </div>
            </div>
            {loading === true ? <Loading /> :
                notes.length === 0 ?
                    <div className="row justify-content-center mt-2">
                        <div className="col">
                            <NoResults />
                        </div>
                    </div>
                    :
                    <div className="row mt-2">
                        <div className="col">
                            <Accordion defaultActiveKey="0">
                                {notes.map((item, index) =>
                                    <Accordion.Item eventKey={index} key={item.id} >
                                        <Accordion.Header>
                                            <table className="ms-2">
                                                <tbody>
                                                    <tr>
                                                        <td className="w-3"><Icon type={item.type} leadCreated={item.leadCreated} /></td>
                                                        <td>
                                                            <p className="mb-0 ms-2">
                                                                <a className="cursor" onClick={() => setEditDialog({ show: true, id: item.id })}>
                                                                    {item.title !== null ?
                                                                        item.title
                                                                    : 
                                                                        t("LEADNOTETYPE." + item.type)
                                                                    }
                                                                </a>
                                                            </p>
                                                            <p className="text-secondary mb-0 ms-2 fs-6">{moment(item.contactTime).format('MMM Do YYYY HH:mm')}</p>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {item.body && <p className="mb-0">{item.body}</p>}
                                            <button type="button" className="btn btn-link btn-sm text-danger" onClick={() => setDeleteDialog({ show: true, id: item.id })}>Delete?</button>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )}
                            </Accordion>
                        </div>
                    </div>
            }
            <Paginate totalPages={totalPages} handlePageClick={handlePageClick} />
            <ConfirmationDialog
                show={deleteDialog.show}
                message="You are about to delete this note. Are you sure?"
                handleConfirm={deleteNote}
                handleCancel={() => setDeleteDialog({ show: false, id: null })}
            />
            <Modal show={editDialog.show} onHide={() => setEditDialog({ show: false })}>
                <Modal.Header closeButton>
                    <Modal.Title>{editDialog.id ? 'Edit Note' : 'Add New Note'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Edit leadId={leadId} noteId={editDialog.id} callback={() => { loadNotes(); setEditDialog({ show: false }) }} />
                </Modal.Body>
            </Modal>
        </>
    )

}