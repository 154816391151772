import { useEffect, useState } from "react";
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import normalizeUrl from 'normalize-url';
import { Footer } from "page/Footer";
import QRCode from "react-qr-code";
import { Loading } from "_components";

// Extracted display components for better organization
const PropertyBasicInfo = ({ content, title, description, address }) => (
    <>
        <h3 className="mb-2"><strong>{title}</strong></h3>
        {description && <h5 className="mb-1" style={{ whiteSpace: "pre-line" }} >{description}</h5>}
        {(address || content.price) && (
            <h6 className="text-secondary">
                {address} {content.price && <> {address === "" ? "" : " - "} {content.price} {content.currency}</>}
            </h6>
        )}
        <div className="row mt-2">
            {content.baths && <div className="col-2"><p>Baths: {content.baths}</p></div>}
            {content.beds && <div className="col-2"><p>Beds: {content.beds}</p></div>}
            {content.totalFlArea && <div className="col-2"><p>Area: {content.totalFlArea}</p></div>}
        </div>
    </>
);

const PropertyDetails = ({ content }) => {
    if (!content) return null;

    return (
        <>
            {content.remarks && <p>{content.remarks}</p>}

            <PropertyInfoSection content={content} />
            <PropertyFeaturesSection content={content} />

            {content.mediaLink && (
                <h5 className="mb-3 mt-2">
                    <a href={normalizeUrl(content.mediaLink)} target="_blank" rel="noreferrer">
                        <strong>Media link</strong>
                    </a>
                </h5>
            )}
        </>
    );
};

const PropertyInfoSection = ({ content }) => {
    const hasPropertyInfo = content.ml || content.area || content.floorAreaFin ||
        content.age || content.fireplaces || content.grossTaxes ||
        content.forTaxYear;

    if (!hasPropertyInfo) return null;

    return (
        <>
            <h5 className="mb-0 mt-3"><strong>Property Information</strong></h5>
            {content.ml && <p className="mb-0">ML#: {content.ml}</p>}
            {content.area && <p className="mb-0">Area: {content.area}</p>}
            {content.floorAreaFin && <p className="mb-0">Floor Area Fin: {content.floorAreaFin}</p>}
            {content.age && <p className="mb-0">Age: {content.age}</p>}
            {content.fireplaces && <p className="mb-0">Fireplaces: {content.fireplaces}</p>}
            {content.grossTaxes && <p className="mb-0">Gross taxes: {content.grossTaxes}</p>}
            {content.forTaxYear && <p className="mb-0">For Tax Year: {content.forTaxYear}</p>}
        </>
    );
};

const PropertyFeaturesSection = ({ content }) => {
    const hasFeatures = content.styleOfHome || content.parking || content.bylawRestrictions ||
        content.construction || content.amenities || content.maintFeeIncludes ||
        content.heating || content.roof;

    if (!hasFeatures) return null;

    return (
        <>
            <h5 className="mb-0 mt-3"><strong>Features</strong></h5>
            {content.styleOfHome && <p className="mb-0">Style of home: {content.styleOfHome}</p>}
            {content.parking && <p className="mb-0">Parking: {content.parking}</p>}
            {content.bylawRestrictions && <p className="mb-0">Bylaw Restrictions: {content.bylawRestrictions}</p>}
            {content.construction && <p className="mb-0">Construction: {content.construction}</p>}
            {content.amenities && <p className="mb-0">Amenities: {content.amenities}</p>}
            {content.maintFeeIncludes && <p className="mb-0">Maint Fee Includes: {content.maintFeeIncludes}</p>}
            {content.heating && <p className="mb-0">Fuel/Heating: {content.heating}</p>}
            {content.roof && <p className="mb-0">Roof: {content.roof}</p>}
        </>
    );
};

const PropertyRooms = ({ rooms }) => {
    if (!rooms || rooms.length === 0) return null;

    return (
        <>
            <h5 className="mb-3 mt-3"><strong>Rooms</strong></h5>
            <div className="row">
                {rooms.map((room) => (
                    <div className="col-lg-6" key={room.id}>
                        <div className="row mb-4">
                            <div className="col-2"></div>
                            <div className="col-8">
                                <h6 className="mb-0"><strong>{room.roomType}</strong></h6>
                                {room.level && <p className="mb-0">Level: {room.level}</p>}
                                {room.dimension1 && <p className="mb-0">Dimension 1: {room.dimension1}</p>}
                                {room.dimension2 && <p className="mb-0">Dimension 2: {room.dimension2}</p>}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

const PropertyGallery = ({ images, showPlayButton = false }) => {
    if (!images || images.length === 0) return null;

    return <ImageGallery items={images} showPlayButton={showPlayButton} />;
};

const PropertyQRCode = ({ id, baseUrl, channelName, invitationCode }) => (
    <div className="text-center mt-4">
        <QRCode
            id={id}
            size={128}
            style={{ height: "auto", maxWidth: "40%", width: "40%" }}
            value={`${baseUrl}/${channelName}/${invitationCode}`}
            viewBox="0 0 256 256"
        />
    </div>
);

export function RealEstate({ offer, invitationCode, channelName, preview, children }) {
    const [loading, setLoading] = useState(true);
    const [content, setContent] = useState(null);
    const [images, setImages] = useState([]);

    useEffect(() => {
        // Process offer data when component mounts
        if (offer) {
            if (offer.content) {
                try {
                    const parsedContent = JSON.parse(offer.content);
                    setContent(parsedContent);
                } catch (error) {
                    console.error("Error parsing offer content:", error);
                }
            }

            // Process images
            if (offer.pictures && Array.isArray(offer.pictures)) {
                const tempImages = offer.pictures.map(item => ({
                    original: `/uploads/offer/${item}`,
                    thumbnail: `/uploads/offer/${item}`
                }));
                setImages(tempImages);
            }
        }

        setLoading(false);
    }, [offer]);

    if (loading) {
        return <Loading />;
    }

    // Create an address string from content data
    const addressString = content?.address1 ?
        `${content.address1} ${content.postalCode || ''} ${content.state || ''}` : '';

    return (
        <>
            <div className="container">
                <div className="row justify-content-center mb-4 mt-4">
                    <div className="col-lg-8">
                        <PropertyBasicInfo
                            content={content || {}}
                            title={offer?.title || ''}
                            description={offer?.description}
                            address={addressString}
                        />
                    </div>
                </div>

                {/* Mobile images view */}
                <div className="d-block d-sm-none">
                    <div className="row justify-content-center">
                        <div className="col">
                            <PropertyGallery images={images} />
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center mb-4">
                    <div className="col-lg-4">
                        <PropertyDetails content={content} />
                    </div>

                    <div className="col col-9 col-md-5 col-lg-4">
                        <div className="d-none d-lg-block d-md-block">
                            <PropertyGallery images={images} />

                            {preview ? (
                                <PropertyQRCode
                                    id={offer.id}
                                    baseUrl={process.env.REACT_APP_BASE_URL}
                                    channelName={channelName}
                                    invitationCode={invitationCode}
                                />
                            ) : (
                                <div className="mt-4">
                                    <div className="row justify-content-center mt-2 mb-1 g-0">
                                        <div className="col col-9 col-md-5 col-lg-8">
                                            {children}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center mb-4">
                    <div className="col-lg-8">
                        {content?.rooms && <PropertyRooms rooms={content.rooms} />}

                        {content?.listingProvidedCourtesyOf && (
                            <p className="mt-3">
                                Listing provided courtesy of: {content.listingProvidedCourtesyOf}
                            </p>
                        )}
                    </div>
                </div>

                {/* Mobile QR code view */}
                <div className="d-block d-sm-none">
                    <div className="row justify-content-center">
                        <div className="col col-9 col-md-5 col-lg-4">
                            {preview ? (
                                <div className="text-center mb-4 mt-4">
                                    <QRCode
                                        id={offer.id}
                                        size={128}
                                        style={{ height: "auto", maxWidth: "40%", width: "40%" }}
                                        value={`${process.env.REACT_APP_BASE_URL}/${channelName}/${invitationCode}`}
                                        viewBox="0 0 256 256"
                                    />
                                </div>
                            ) : (
                                <div className="mt-4">
                                    {children}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
}