import { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'

import { ProfileField } from "_components";
import { fetchWrapper } from '_helpers';
import { isMobile } from 'react-device-detect';

import { About, Email, Image, Occupation, PhoneNumbers, Socials } from ".";
import { useSelector } from "react-redux";
import { Addresses } from "_components/Addresses";
import { Link } from "react-router-dom";
import { BusinessCardPreview } from "./BusinessCardPreview";
import { Wallets } from "vendor/Wallets";

export function Profile() {
    const authUser = useSelector(x => x.auth.user);
    const [profile, setProfile] = useState();
    const [showPreview, setShowPreview] = useState(false);
    const [invitationCode, setInvitationCode] = useState(null);

    useEffect(() => {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/profile").then(response => {
            setProfile(response.data);
            getInvitationCode();
        });
    }, [0])

    function getInvitationCode() {
        fetchWrapper.get(process.env.REACT_APP_API_URL + "/profile/lead-invitation").then(response => {
            setInvitationCode(response.data.value);
        });
    }

    return (
        <>
            {profile &&
                <div className="row justify-content-center mt-4 mb-4">
                    <div className="col-lg-8 col-xl-8">
                        {isMobile &&
                            <div className="row">
                                <div className="col">
                                    <button type='button' className="btn" onClick={() => setShowPreview(true)}><FontAwesomeIcon icon={faEye}/> Preview</button>
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-lg-3 text-center">
                                <Image profile={profile} />
                            </div>
                            <div className="col-lg-6">
                                <ProfileField data={profile} name="firstName" label="First Name" editAllowed={true} deleteAllowed={false} apiEndpoint="/profile/first-name" />
                                <ProfileField data={profile} name="lastName" label="Last Name" editAllowed={true} deleteAllowed={false} apiEndpoint="/profile/last-name" />
                                <Occupation profile={profile} />
                                <Email profile={profile} />
                                <PhoneNumbers />
                                <Addresses
                                    loadUrl="/profile/addresses"
                                    editUrl="/profile/address"
                                    editAllowed={true}
                                    description="Enter the physical addresses of your business."
                                />
                                <About profile={profile} />
                                <Socials profile={profile} />
                            </div>
                            {!isMobile &&
                                <div className="col-lg-3">
                                    <div className="row">
                                        <div className="col">
                                            <button type='button' className="btn" onClick={() => setShowPreview(true)}><FontAwesomeIcon icon={faEye} /> Preview</button>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <Wallets />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
            <BusinessCardPreview
                showPreview={showPreview}
                onHide={() => setShowPreview(false)}
                channelName={authUser.channelName}
                invitationCode={invitationCode}
            />
        </>
    )

}