import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useState, useEffect } from "react";

import { constants } from '_helpers';
import { PhoneNumberInputField, TextAreaField, TextInputField } from "_components";

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('Please enter your first name'),
    lastName: Yup.string().required('Please enter your last name'),
    'email': Yup.string().when('phoneNumber', {
        is: (phoneNumber) => !phoneNumber || phoneNumber.length === 0,
        then: Yup.string().required('Email or phone number is required field').email("Please enter a valid email address"),
    }),
    'phoneNumber': Yup.string().matches(constants.phoneRegExp, 'Phone number is not valid')
}, ['email', 'phoneNumber']);

export function SubscribeForm({
    onSubmit,
    submitButtonText = "Confirm",
    showInterestsField = true,
    showTermsAcceptance = true,
    termsLinks = {
        consent: "",
        terms: ""
    },
    defaultValues = {},
    firstName = "",
    lastName = "",
    phoneNumber = "",
    email = "",
    readOnlyPhone = false,
}) {
    const [acceptedTerms, setAcceptedTerms] = useState(!showTermsAcceptance);

    // Prepare default values with props
    const formDefaultValues = {
        ...defaultValues,
        firstName: firstName || defaultValues.firstName || "",
        lastName: lastName || defaultValues.lastName || "",
        phoneNumber: phoneNumber || defaultValues.phoneNumber || "",
        email: email || defaultValues.email || "",
    };

    // form options
    const formOptions = {
        resolver: yupResolver(validationSchema),
        criteriaMode: "firstError",
        shouldFocusError: true,
        defaultValues: formDefaultValues
    };

    const {
        control,
        register,
        handleSubmit,
        formState,
        getValues,
        setValue,
        setError,
        reset
    } = useForm(formOptions);

    // Update form when props change
    useEffect(() => {
        reset({
            ...getValues(),
            firstName: firstName || getValues().firstName,
            lastName: lastName || getValues().lastName,
            phoneNumber: phoneNumber || getValues().phoneNumber,
            email: email || getValues().email,
        });
    }, [firstName, lastName, phoneNumber, email, reset, getValues]);

    const { errors, isSubmitting } = formState;

    function handleChecked(event) {
        setAcceptedTerms(event.target.checked);
    }

    const handleFormSubmit = async (data) => {
        if (onSubmit) {
            try {
                await onSubmit(data, { getValues, setError });
            } catch (error) {
                setError("phoneNumber", {
                    type: 'custom',
                    message: "Something went wrong. Please try again."
                }, { shouldFocus: true });
            }
        }
    };

    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <TextInputField
                name="firstName"
                label="First Name"
                error={errors.firstName}
                register={register}
            />
            <TextInputField
                name="lastName"
                label="Last Name"
                error={errors.lastName}
                register={register}
            />
            <PhoneNumberInputField
                label="Cell"
                name="phoneNumber"
                control={control}
                readonly={readOnlyPhone}
            />
            <TextInputField
                name="email"
                label="E-mail"
                error={errors.email}
                register={register}
            />

            {showInterestsField && (
                <TextAreaField
                    register={register}
                    setValue={setValue}
                    label="Share your interest"
                    name="interests"
                    error={errors.interests}
                    maxLines={3}
                    maxChars={255}
                    rows={3}
                />
            )}

            {showTermsAcceptance && (
                <div className="form-check">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id="accept-terms"
                        onChange={handleChecked}
                    />
                    <label className="form-check-label" htmlFor="accept-terms">
                        I give <a href={termsLinks.consent || ""}>Consent</a> and Accept <a href={termsLinks.terms || ""}>Terms and Conditions</a>
                    </label>
                </div>
            )}

            <div className="d-grid gap-2 mt-1 mb-3">
                <button
                    disabled={isSubmitting || acceptedTerms === false}
                    className="btn btn-lg btn-dark mt-3 rounded-pill"
                >
                    {isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                    {submitButtonText}
                </button>
            </div>
        </form>
    );
}